import * as React from 'react';

import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

const AddIcon = (props: SvgIconProps) => {

  return (
    <SvgIcon {...props}>
      <svg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <g id='Icon' clipPath='url(#clip0_8667_5058)'>
          <path id='Vector 2165' d='M1.66797 8.21875L15.668 8.21875' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
          <path id='Vector 2166' d='M8.66797 1.21875V15.2187' stroke='currentColor' strokeWidth='2' strokeLinecap='round' />
        </g>
        <defs>
          <clipPath id='clip0_8667_5058'>
            <rect width='16' height='16' fill='white' transform='translate(0.667969 0.21875)' />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default AddIcon;