import {
  Divider,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
  Typography,
} from '@mui/material';

import MenuData from '../../types/ui/menu-data';
import React from 'react';
import useStyles from './styles';

/**
 * Props interface extending from MenuProps
 * @interface
 */
interface Props extends MenuProps {
  onMenuItemSelected: (item: MenuData) => void;
  menuList: Array<MenuData>;
  isInboxMenu?: boolean;
  style?: React.CSSProperties;
}

/**
 * MenuSelectDialog Functional Component
 * 
 * This component renders a selectable menu dialog using MUI components.
 * 
 * @param {Props} props - The props for the component
 * @returns {JSX.Element} The rendered MenuSelectDialog component
 */
const MenuSelectDialog: React.FC<Props> = (props: Props) => {

  const { menuList, onMenuItemSelected, isInboxMenu, style, ...rest } = props;
  const styles = useStyles(isInboxMenu);

  return (
    <Menu
      {...rest}
      id='menu-appbar'
      slotProps={{ paper: { sx: styles.menuPaperProps } }}
      MenuListProps={{ sx: styles.menu }}
      anchorOrigin={
        isInboxMenu
          ? { vertical: 'top', horizontal: 'right' }
          : { vertical: 'bottom', horizontal: 'center' }
      }
      transformOrigin={
        isInboxMenu
          ? { vertical: 'bottom', horizontal: 'center' }
          : { vertical: 'top', horizontal: 'center' }
      }
      sx={
        (isInboxMenu && style)
          ? {
              '& .MuiPaper-root': {
                ...style, // Fix: Use the passed `style` prop
              },
            }
          : {}
      }
    >
      {menuList.flatMap((option, index) => [
        <MenuItem
          key={`menu-${index}-${option.id}`}
          value={option.id}
          sx={styles.menuItem(option.isDestructive, option.isPrimary)}
          onClick={() => onMenuItemSelected(option)}
        >
          <ListItemIcon sx={styles.iconWrapper(option.isDestructive, option.isPrimary)}>
            {option.icon}
          </ListItemIcon>
          <ListItemText>
            <Typography variant={'p1'}>{option.value}</Typography>
          </ListItemText>
        </MenuItem>,
        index !== menuList.length - 1 && (
          <Divider key={`divider-${index}`} sx={styles.divider} />
        ),
      ])}
    </Menu>
  );
};

export default MenuSelectDialog;
