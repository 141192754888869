import Dimens from '../../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the ProfileStatus component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
  const { base } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      p: `0 ${base} ${base} ${base}`,
      fontSize: Dimens.fontSize.p1,
      [ theme.breakpoints.up('sm') ]: {
        border: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        borderRadius: Dimens.radius.md,
        justifyContent: 'center',
        mt: base,
        p: base,
      },
      minWidth: Dimens.menuItemMinWidth,
    },
    statusBox: { 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
      justifyContent: 'space-between', 
      width: '100%' 
    },
    statusInfo: { 
      display: 'flex', 
      flexDirection: 'row', 
      alignItems: 'center', 
    },
    statusText: {
      color: theme.palette.text.secondary,
      textAlign: 'center'
    },
    statusLable: {
      display: 'inline',
      pl:Dimens.spacing.xxs,
      [ theme.breakpoints.up('sm') ]: {
        display: 'none'
      }
    },
    iconWrapper: {
      [ theme.breakpoints.down('sm') ]: {
        pl: Dimens.spacing.base
      },
      '&.MuiListItemIcon-root': {
        minWidth: '0 !important',
        mr: Dimens.spacing.base,
        color: 'inherit',
        '& .MuiSvgIcon-root': {
          fontSize: Dimens.icon.sm
        }
      },
    },
    caretDownIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    iconButton :{
      [ theme.breakpoints.up('sm') ]: {
        display: 'none'
      }
    }
  };

  return styles;
};

export default useStyles;
