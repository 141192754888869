import Dimens from '../../../../theme/dimens';
import { MessagePriority } from '../../../../utils/constants';
import ThemeUtil from '../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the ChatFooter component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (isUrgent = false) => {

  const { xxs, xs, sm, lg, section, base, paper } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    footer: {
      display: 'flex',
      flexDirection: 'column',
      borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      p: section,
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
		msgLayout: {
      display: 'flex',
      flexDirection: 'row',
			flex: 1,
      alignItems: 'center',
			width: '100%'
    },
		previewLayout: {
			pt: lg
		},
    textField: {
      flex: 1,
      mx: sm,
			'& .MuiOutlinedInput-root': {
				'input': {
					height: 'inherit'
				},
				'& fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: theme.palette.background.default
				},
				'&:hover fieldset': {
					borderColor: theme.palette.background.default
				},
				'&.Mui-focused fieldset': {
					borderWidth: Dimens.inputBorderWidth,
					borderColor: theme.palette.primary.main
				}
			},
			'& .MuiInputBase-root.MuiOutlinedInput-root ::placeholder': {
				...theme.typography.p1,
				color: isUrgent ? theme.palette.error.main : theme.palette.text.secondary,
				opacity : 1
			},
			'.MuiInputBase-multiline': {
				p: 0
			}
		},
    outerInput: {
			borderRadius: Dimens.radius.xl,
			backgroundColor: theme.palette.background.default
		},
    innerInput: {
			...theme.typography.p1,
			color: isUrgent ? theme.palette.error.main : theme.palette.text.primary,
      flex: 1,
			padding: `${xs} 0 ${xs} ${lg}`
		},
    iconBtn: {
      background: theme.palette.background.default,
      p: `${sm} ${lg}`,
			m: 0,
      radius: Dimens.radius.md,
			'svg': {
				color: isUrgent ? theme.palette.error.main : theme.palette.text.secondary,
				fontSize: Dimens.icon.sm
			}
    },
		fab: {
			width: 'unset',
			height: 'unset',
			minHeight: 'unset',
			p: sm,
			boxShadow: 'none !important',
			'&.MuiFab-secondary': {
				color: isUrgent ? theme.palette.error.main : theme.palette.text.secondary
			},
			'svg': {
				fontSize: Dimens.icon.sm
			}
		},
		msgError: {
			color: theme.palette.error.main,
			pt: xxs,
			textAlign: 'center'
		},
		attachmentMenu:{
			transform: `translate(${paper}, -${base}) !important`,
		},
		priorityMenu: {
			transform: `translate(0px, -${base}) !important`,
		}
  };

  return styles;
};

export default useStyles;