import { Box, Dialog, IconButton, Stack, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useState } from 'react';

import APP_NAV from '../../routes/app-nav';
import CircularProgressBar from '../../components/CircularProgressBar';
import CrossIcon from '../../components/CustomIcons/CrossIcon';
import EditIcon from '../../components/CustomIcons/EditIcon';
import EditProfile from './EditProfileDialog';
import EditProfileDialog from './EditProfileDialog';
import { FETCH_PROFILE } from '../../data/profile/action-types';
import InlineAlert from '../../components/InlineAlert';
import LoginUtil from '../../utils/login-util';
import MenuData from '../../types/ui/menu-data';
import MenuIcon from '../../components/CustomIcons/MenuIcon';
import { PROFILE_SUB_MENU } from '../../utils/ui-constants';
import ProfileStatus from './ViewProfile/ProfileStatus';
import SettingScreen from '../SettingScreen';
import TopBar from '../../components/TopBar';
import Util from '../../utils/util';
import ViewProfile from './ViewProfile';
import theme from '../../theme/theme';
import { useNavigate } from 'react-router-dom';
import { useProfileApi } from '../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * ProfileScreen Component
 *
 * This component serves as the main screen for viewing and managing the user's profile. 
 * It handles fetching user details, displaying profile information, and allowing the user to edit their profile.
 * The layout is responsive, adjusting for mobile and tablet views.
 *
 * @component
 */
const ProfileScreen: React.FC = () => {

	const styles = useStyles();
	const { t } = useTranslation();
	const [ openEditProfileDialog, setOpenEditProfileDialog ] = useState<boolean>(false);
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));
	const profileApi = useProfileApi();
	const [ status, setStatus ] = useState<MenuData>(PROFILE_SUB_MENU[ 0 ]);
	const profileState = profileApi.state.profile;
	const navigate = useNavigate();

	/**
	 * Fetches profile details using the stored login ID (email).
	 * Triggered once when the component is mounted.
	 */
	useEffect(() => {
		profileApi.fetchLogedInUserDetails();
	}, []);

	/**
	 * Handles the action of editing the profile, with different behavior based on device type.
	 * 
	 * - If the user is on a non-mobile device (e.g., desktop), it will open the "Edit Profile" dialog.
	 * - If the user is on a mobile device, it navigates to the settings page.
	 */
	const handleEditAction = () => {
		if (!isMobile) {
			setOpenEditProfileDialog(true);
		} else {
			navigate(APP_NAV.SETTINGS);
		}
	};

	return (
		<Box sx={styles.container}>
			<TopBar
				title={isMobile ? t('profile') : t('userProfile')}
				subTitle={!isMobile ? t('userProfileSubtitle') : ''}
				hasDivider={!isTablet}
				actionText={!isMobile ? t('edit') : ''}
				startActionIcon={!isMobile ? <EditIcon /> : undefined}
				hasIconButton={isMobile}
				iconButtonIcon={isMobile ? <MenuIcon /> : undefined}
				onActionClick={handleEditAction}
			/>
			<InlineAlert message={Util.getApiError([FETCH_PROFILE], profileApi.state.profile.apiStatus)} />
			{isMobile &&
				<ProfileStatus initialStatus={status} onStatusChange={(menu) => setStatus(menu)} />
			}
			{profileState.user &&
				<ViewProfile initialUser={profileState.user} status={status} onStatusChange={(menu) => setStatus(menu)} />
			}
			{(profileState.user && openEditProfileDialog) &&
				<EditProfileDialog
					user={profileState.user}
					open={openEditProfileDialog}
					onClose={() => setOpenEditProfileDialog(false)}
				/>
			}
			<CircularProgressBar show={Util.isApiLoading([FETCH_PROFILE], profileApi.state.profile.apiStatus)} />  
		</Box>
	);
};

export default ProfileScreen;
