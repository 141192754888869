import { Box, CardMedia, IconButton, Typography } from '@mui/material';
import React, { useMemo } from 'react';

import AudioPlayer from '../../../../../components/AudioPlayer';
import ChatUtil from '../../../../../utils/chat-util';
import { ContentType } from '../../../../../utils/constants';
import DownloadIcon from '../../../../../components/CustomIcons/DownloadIcon';
import MessageData from '../../../../../types/message-data';
import PdfIcon from '../../../../../components/CustomIcons/PdfIcon';
import PlayIcon from '../../../../../components/CustomIcons/PlayIcon';
import Util from '../../../../../utils/util';
import useStyles from './styles';

export interface Props {
  messageData: MessageData;
  onAttachmentClick: () => void;
}

/**
 * Renders a component for displaying various document types (PDF, image, video, audio).
 *
 * @param {Props} props - Component props containing `messageData` and `onAttachmentClick` function.
 * 
 * @returns {JSX.Element} JSX element representing the DocumentView component.
 */
const DocumentView: React.FC<Props> = (props: Props) => {

  const { messageData } = props;
  const styles = useStyles(props);

  /**
   * Handles clicks on the attachment container.
   *
   * @param {React.MouseEvent<HTMLDivElement, MouseEvent>} e - The click event object.
   */
  const onAttachmentClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    if (!messageData.file || (messageData.contentType !== ContentType.ContentAudio)) {
      props.onAttachmentClick();
    }
  }

  /**
   * Renders the PDF view for the document.
   *
   * @returns {JSX.Element} JSX element representing the PDF view.
   */
  const renderPDFView = () => (
    <Box sx={styles.pdfContainer} onClick={onAttachmentClick}>
      <PdfIcon />
      <Box>
        <Typography variant='p1' sx={styles.contentText}>
          {Util.getFilenameFromPath(messageData.attachmentKey ?? '')}
        </Typography>
        {messageData.file &&
          <Typography variant='footer' sx={styles.contentSize}>
            {Util.formatFileSize(messageData.file.size)}
          </Typography>
        }
      </Box>
      {!messageData.file &&
        <IconButton sx={styles.downloadBtn}>
          <DownloadIcon />
        </IconButton>
      }
    </Box>
  );

  /**
   * Renders the image view for the document.
   *
   * @returns {JSX.Element} JSX element representing the image view.
   */
  const renderImageView = () => (
    <Box sx={styles.imageContainer} onClick={onAttachmentClick}>
      {messageData.file ? (
        <CardMedia
          component='img'
          src={URL.createObjectURL(messageData.file)}
          onContextMenu={e => e.preventDefault()}
        />
      ) : (
        <IconButton sx={styles.downloadBtn}>
          <DownloadIcon />
        </IconButton>
      )}
    </Box>
  );

  /**
   * Renders the video view for the document.
   *
   * @returns {JSX.Element} JSX element representing the video view.
   */
  const renderVideoView = () => useMemo(() => (
    <Box sx={styles.videoContainer} onClick={onAttachmentClick}>
      {messageData.file ? (
        <>
          <CardMedia
            component='video'
            src={URL.createObjectURL(messageData.file)}
            onContextMenu={e => e.preventDefault()}
          />
          <IconButton sx={styles.playBtn}>
            <PlayIcon />
          </IconButton>
        </>
      ) : (
        <IconButton sx={styles.downloadBtn}>
          <DownloadIcon />
        </IconButton>
      )}
    </Box>
  ), [messageData.file]);

  /**
   * Renders the audio view for the document.
   *
   * @returns {JSX.Element} JSX element representing the audio view.
   */
  const renderAudioView  = () => useMemo(() => (
    <Box sx={styles.audioContainer} key={messageData.messageId ?? messageData.localMessageId}>
      <AudioPlayer
        src={messageData.file ? URL.createObjectURL(messageData.file) : undefined}
        onDownloadClick={onAttachmentClick}
        isReceived={!ChatUtil.isLoggedInUser(messageData.senderLoginId ?? '')}
        priority={messageData.priority}
      />
    </Box>
  ), [messageData.file]);

  /**
   * Renders the appropriate document view based on the content type.
   *
   * @returns {JSX.Element} JSX element representing the document view.
   */
  const renderDocumentView = () => {
    switch (messageData.contentType) {
      case ContentType.ContentPdf:
      case ContentType.ForwardPdf:
        return renderPDFView();
      case ContentType.ContentImage:
      case ContentType.ForwardImage:
        return renderImageView();
      case ContentType.ContentVideo:
      case ContentType.ForwardVideo:
        return renderVideoView();
      case ContentType.ContentAudio:
      case ContentType.ForwardAudio:
        return renderAudioView();
      default:
        return <></>;
    }
  }

  return renderDocumentView();
};

export default DocumentView;
