import { Box, Stack, Typography } from '@mui/material';

import AppInfo from '../../../../../package.json';
import MenuList from '../../SettingsMenuList';
import React from 'react';
import { SETTINGS_HELP_MENU_OPTIONS } from '../../../../utils/ui-constants';
import SettingsMenuData from '../../../../types/ui/settings_menu_data';
import { SettingsSubMenu } from '../../../../utils/constants';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * HelpSubMenu Component
 * 
 * This component renders a list of help-related menu options for the user. Depending on the selected menu item,
 * it either opens an external link in a new tab or opens the user's default email client with a pre-filled email for contacting support.
 * 
 * Menu options include "Contact Support", "Privacy Policy", and "Terms & Conditions".
 * 
 * @component
 * @returns {JSX.Element} JSX element representing the help submenu.
 */
const HelpSubMenu: React.FC = () => {

	const styles = useStyles(); // Retrieve custom styles for the component
	const { t } = useTranslation();
	/**
	 * handleItemClick
	 * 
	 * Handles the click event when a user selects a menu item from the help submenu. Based on the selected item:
	 * - "Contact Support": Opens the default email client with a pre-filled support request email.
	 * - "Privacy Policy": Opens a new tab with the company's privacy policy webpage.
	 * - "Terms & Conditions": Opens a new tab with the company's terms and conditions webpage.
	 * 
	 * @param {SettingsMenuData} selectedMenu - The menu item selected by the user.
	 */
	const handleItemClick = (selectedMenu: SettingsMenuData) => {

		if (selectedMenu.id === SettingsSubMenu.ContactSupport) {
			// Open system email client with a pre-filled "to" field and subject for support requests
			const email = 'anjali.munde@multitone.com';
			const subject = encodeURIComponent('Support Request');
			const body = encodeURIComponent('Please describe your issue here.');
			window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank');
		} else if (selectedMenu.id === SettingsSubMenu.PrivacyPolicy) {
			// privacy policy page
			window.open('https://www.multitone.com/privacy-policy/', '_blank');
		} else if (selectedMenu.id === SettingsSubMenu.TermsAndConditions) {
			// terms and conditions page
			window.open('https://www.multitone.com/terms-and-conditions/', '_blank');
		}
	};

	return (
		<Box sx={styles.container}>
			<MenuList
				menuItems={SETTINGS_HELP_MENU_OPTIONS}
				onItemClick={handleItemClick}
			/>
			<Stack sx={styles.versionWrapper}>
        <Typography variant='footer'>{`${t('version')} ${AppInfo.version}`}</Typography>
      </Stack>
		</Box>
	);
};

export default HelpSubMenu;
