import React, { useContext } from 'react';
import {
	apiFailure,
	initProfileFetch,
	initProfileUpdate,
	profileSuccess,
	resetErrorState,
	resetProfileState
} from './actions';
import { doGet, doMultipartPut, doPost, doPut } from '../../service';

import APIConfig from '../../service/api-config';
import AppError from '../../exception/app-error';
import { HttpStatusCode } from 'axios';
import LoginUtil from '../../utils/login-util';
import ProfileImageResponse from '../../types/profile-img-response';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import StatusRequest from '../../types/status-request';
import { Store } from '../../store/store';
import { User } from '../../types/user';
import { UserDetailResponse } from '../../types/user-detail-response';
import { UserUpdateRequest } from '../../types/user-update-request';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * Custom hook for interacting with the profile API.
 *
 * This hook provides functions to fetch and update user profile details, reset profile state,
 * and handle API errors. It interacts with the Redux store to manage state and dispatch actions
 * based on API responses and user interactions.
 *
 * @returns {Object} An object containing the following methods:
 *   - `fetchUserDetailsByEmailId`: Function to fetch user profile details by email ID.
 *   - `updateProfileDetail`: Function to update user profile information.
 *   - `resetProfile`: Function to reset the profile state in the Redux store.
 *   - `resetError`: Function to reset any error state related to profile actions.
 *   - `state`: The current state from the Redux store.
 */
export function useProfileApi() {
	
	const { state, dispatch } = React.useContext(Store);
	const { t } = useTranslation();
	const { setSessionExpired } = useContext(SessionExpirationContext);

	/**
	 * Fetches detailed profile information for a user based on their email ID.
	 *
	 * Dispatches `initProfileFetch` to indicate the start of the fetch process,
	 * and `profileSuccess` if the profile is fetched successfully. In case of failure,
	 * it dispatches an error action.
	 *
	 * @param {string} emailId - The email ID of the user whose profile is being fetched.
	 * @returns {Promise<void>} - A promise that resolves when the profile fetch is completed.
	 */
	const fetchLogedInUserDetails = useCallback(async () => {
			dispatch(initProfileFetch());
			try {
				const response: UserDetailResponse = await doGet(APIConfig.ownDetail);
				const user: User = {
					...response.data.profile, // Spread profile properties
					departments: response.data.departmentAndSites?.departments || [], // Add departments
					sites: response.data.departmentAndSites?.sites || [], // Add sites
					isCompleted: true, // Mark as completed
					defaultDepartmentId: response.data.defaultDepartment?.id || '', // Add default department ID
					pager: response.data.pager
				};
				dispatch(profileSuccess(user));

				return user;
			} catch (error: any) { /* eslint-disable-line */
				dispatchFailureAction(error);
			}
		}, [state.profile]);

	/**
	 * Updates the user's profile details.
	 *
	 * Takes a `User` object with updated information, constructs an update request, and sends it
	 * to the API. On success, it dispatches `profileSuccess` with the updated user data. If there
	 * is an error, it dispatches an `apiFailure` action.
	 *
	 * @param {User} profileRequest - The user object containing updated profile details.
	 * @returns {Promise<UserDetailResponse>} A promise that resolves to the API response.
	 */
	const updateProfileDetail = useCallback(async (profileRequest: User) => {
		dispatch(initProfileUpdate());
		try {
			const { name, email, type, position, about, defaultDepartmentId, departments = [] } = profileRequest;
			const updateRequest: UserUpdateRequest = {
				profile: { name, email, type, position, about, defaultDepartmentId },
				loginId: email,
				defaultDepartmentId: defaultDepartmentId,
				departmentIds: departments.map(department => department.id)
			};
			const response: UserDetailResponse = await doPut(APIConfig.updateOwnDetail, updateRequest);

			const user: User = {
				...response.data.profile,
				departments: response.data.departmentAndSites?.departments ?? [],
				sites: response.data.departmentAndSites?.sites ?? [],
				isCompleted: true, // Mark as completed
				defaultDepartmentId: response.data.defaultDepartment?.id,
				pager: response.data.pager
			};
			dispatch(profileSuccess(user));

			return response.data;
		} catch (error: any) {/* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Updates the user's profile image.
	 *
	 * Takes a File object representing the new profile image, constructs a form data request,
	 * and sends it to the API. On success, it updates the user's image in the store and
	 * dispatches profileSuccess with the updated user data. If there is an error, it
	 * dispatches an apiFailure action.
	 *
	 * @param {File} imageFile - The image file to be uploaded as the user's new profile picture.
	 * @returns {Promise<ProfileImage>} A promise that resolves to the API response containing the updated image information.
	 */
	const updateProfileImage = useCallback(async (imageFile: File) => {
		dispatch(initProfileUpdate());
		try {
			const formData = new FormData();
			formData.append('file', imageFile);
			const response: ProfileImageResponse = await doMultipartPut(APIConfig.profileImage, formData);
			// update image of the user
			const updatedUser = { ...state.profile.user, image: response.data };
			dispatch(profileSuccess(updatedUser));
			return updatedUser;
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, [state.profile.user]);

	/**
	 * Updates the user's profile status.
	 *
	 * Takes a `StatusRequest` object representing the new status for the user's profile,
	 * and sends it to the API. On success, it completes the status update operation.
	 * If there is an error, it dispatches an `apiFailure` action.
	 *
	 * @param {StatusRequest} statusRequest - The request object containing the new status to be set for the user's profile.
	 * @returns {Promise<void>} A promise that resolves when the status update operation is complete.
	 */
	const updateUserStatus = useCallback(async (status: string) => {
		const statusRequest: StatusRequest = {
			loginId: LoginUtil.getLoginId(),
			deviceId: LoginUtil.getClientId(),
			assignedSessionStatus: status,
		};
		try {
			await doPost(APIConfig.setProfileStatus, statusRequest);
		} catch (error: any) { /* eslint-disable-line */
			dispatchFailureAction(error);
		}
	}, []);

	/**
	 * Resets the API error state for the profile actions.
	 *
	 * If the profile's API status has an error, this function will dispatch an action
	 * to reset the error state.
	 */
	const resetError = () => {
		if (state.profile.apiStatus?.error) {
			dispatch(resetErrorState());
		}
	};

	/**
	 * Resets the user's profile state in the store.
	 *
	 * This function can be used to clear the current profile information from the application state.
	 */
	const resetProfile = () => {
		dispatch(resetProfileState());
	}

	/**
	 * Dispatches an API failure action with the given error.
	 *
	 * If the error message is missing, a default error message is retrieved from translations.
	 *
	 * @param {any} error - The error object to handle.
	 */
	const dispatchFailureAction = (error?: any) => { /* eslint-disable-line */
		const message: string = error?.message || t('defaultErrorMsg');
		dispatch(apiFailure(new AppError(error?.code, message)));
		if (error?.code === HttpStatusCode.Unauthorized) {
			setSessionExpired(true);
		}
	}

	return {
		fetchLogedInUserDetails,
		updateProfileDetail,
		updateProfileImage,
		updateUserStatus,
		resetProfile,
		resetError,
		state
	};
}
