import Dimens from '../../../theme/dimens'
import { useTheme } from '@mui/material'
import zIndex from '@mui/material/styles/zIndex'

/**
 * Custom hook to generate styles for the ViewProfile component.
 *
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = () => {
  const { xxs, sm, lg, section, container } = Dimens.spacing
  const theme = useTheme()

  const styles = {
    container: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      minHeight: 0,
      overflowY: 'auto'
    },
    userHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      p: container,
      [theme.breakpoints.up('md')]: {
        borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`
      }
    },
    userInfo: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      p: `${section} ${section} 0 ${section}`,
      [theme.breakpoints.down('sm')]: {
        p: `${lg} 0 0 ${lg}`
      }
    },
    title: {
      fontWeight: Dimens.fontWeight.bold,
      textAlign: 'center',
      pb: xxs
    },
    subTitle: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
      pt: xxs
    },
    profileInfo: {
      pt: section,
      display: 'flex',
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'center',
      minHeight: 0,
      [theme.breakpoints.down('md')]: {
        p: lg,
        flexDirection: 'column',
        mx: 0
      }
    },
    tabListWrapper: {
      flex: 1,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      minHeight: '0'
    },
    tabList: {
      display: 'flex',
      borderBottom: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
      borderColor: theme.palette.background.default,
      px: section,
      minHeight: 0,
      [theme.breakpoints.down('sm')]: {
        px: sm
      }
    },
    tab: {
      flexBasis: '50%',
      textAlign: 'center'
    },
    tabPanel: {
      display: 'flex',
      flexGrow: 1,
      [theme.breakpoints.down('sm')]: {
        px: xxs
      }
    },
    divider: {
      color: theme.palette.primary.main,
      width: Dimens.menuBorderWidth,
      mx: Dimens.spacing.xs,
      pt: Dimens.spacing.xxs
    },
    userPositionBox: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      pb:Dimens.spacing.xl
    }
  }

  return styles;
}

export default useStyles;
