// StatusBox.tsx

import { ActualSessionStatus, AssignedSessionStatus } from '../../../../utils/constants';
import { Box, IconButton, ListItemIcon, Typography } from '@mui/material';
import React, { useState } from 'react';

import ChevronDownIcon from '../../../../components/CustomIcons/ChevronDownIcon';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import { PROFILE_SUB_MENU } from '../../../../utils/ui-constants';
import theme from '../../../../theme/theme';
import { useProfileApi } from '../../../../data/profile/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Props interface extending from MenuProps
 * @interface
 */
interface Props {
	initialStatus: MenuData;
	onStatusChange: (item: MenuData) => void;
}

/**
 * ProfileStatus Component
 * 
 * Displays the current status with an icon and a menu to select different statuses.
 * 
 * @param {Props} props - The props for the component.
 * @param {MenuData} props.initialStatus - The initial status to display.
 * @param {(status: MenuData) => void} props.onStatusChange - Callback function to handle status changes.
 * 
 * @returns {JSX.Element} The rendered ProfileStatus component.
 */
const ProfileStatus: React.FC<Props> = (props: Props) => {

	const styles = useStyles();
	const { t } = useTranslation();
	const { initialStatus, onStatusChange } = props;
	const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
	const [ status, setStatus ] = useState<MenuData>(initialStatus);
	const profileApi = useProfileApi();
	const isClickEnabled = theme.breakpoints.up('md');

	/**
	 * Opens the menu when the status box is clicked.
	 * 
	 * @param {React.MouseEvent<HTMLElement>} event - The click event.
	 */
	const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	/**
	 * Handles menu item selection, updates the status, and closes the menu.
	 * 
	 * @param {MenuData} item - The selected menu item.
	 */
	const handleMenuItemSelected = (item: MenuData) => {
		setStatus(item);
		onStatusChange(item);
		// Map "AVAILABLE" to "ONLINE" before sending
		const status = item.id === AssignedSessionStatus.Available ? ActualSessionStatus.Online : item.id.toString();
		profileApi.updateUserStatus(status);
		setAnchorEl(null);
	};

	/**
	 * Finds the icon associated with a given status.
	 * 
	 * @param {string | number} status - The status identifier.
	 * 
	 * @returns {React.ReactNode} The icon for the given status or null if not found.
	 */
	const getIconForStatus = (status: string | number) => {
		const selectedItem = PROFILE_SUB_MENU.find((item) => item.id === status);
		return selectedItem ? selectedItem.icon : null; // Return the icon if found, else null
	};

	return (
		<>
			<Box sx={styles.container} onClick={isClickEnabled ? handleMenuClick : undefined}>
				<Box sx={styles.statusBox}>
					<Box sx={styles.statusInfo}>
						<Typography variant='subheading' sx={styles.statusLable} >{t('status')}:</Typography>
						<ListItemIcon sx={{ ...styles.iconWrapper, }}>
							{getIconForStatus(status.id)}
						</ListItemIcon>
						<Typography component={'span'} variant='subheading' sx={styles.statusText}>{status.value} </Typography>
					</Box>
					<IconButton sx={styles.iconButton} onClick={handleMenuClick}>
						<ChevronDownIcon sx={styles.caretDownIcon} />
					</IconButton>
				</Box>
			</Box>
			<MenuSelectDialog
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={() => setAnchorEl(null)}
				menuList={PROFILE_SUB_MENU}
				onMenuItemSelected={handleMenuItemSelected}
			/>
		</>
	);
};

export default ProfileStatus;
