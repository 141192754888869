import Dimens from '../../theme/dimens';
import useCommonStyles from '../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook
 *
 * This hook creates MUI styles for the Domain Screen component. It utilizes theme values and common styles
 * from `Dimens` and `useCommonStyles`.
 *
 * @returns {Object} An object containing styled classes for various components within the Domain Screen.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();
  const { mini, xxs, sm, base, lg, section, container } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flexDirection: 'column'
    },
    content: {
      p: section,
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      'form >div.MuiStack-root': {
        minWidth: Dimens.inputMinWidth,
        [theme.breakpoints.down('sm')]: {
          minWidth: 'unset',
          flex: 1
        }
      },
      [theme.breakpoints.down('sm')]: {
        p: lg
      }
    },
    form: {
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'start'
    },
    btnWrapper: {
      pl: base,
      pt: container,
      mt: mini
    },
    chipWrapper: {
      ...commonStyles.noScrollBar,
      maxWidth: Dimens.chipWrapperMaxWidth,
      overflowY: 'auto'
    },
    chip: {
      m: `${xxs} ${xxs} ${xxs} 0`,
      borderRadius: Dimens.radius.md,
      p: `${sm} ${lg}`,
      height: 'auto',
      '.MuiChip-label': {
        ...theme.typography.p1,
        ...commonStyles.textEllipsis(1),
        whiteSpace: 'normal',
        p: 0
      },
      '.MuiChip-deleteIcon': {
        display: 'flex',
        m: 0,
        ml: base,
        fontSize: Dimens.icon.sm,
        color: theme.palette.text.primary,
        '&:hover': {
          color: theme.palette.text.primary
        }
      }
    }
  };

  return styles;
};

export default useStyles;