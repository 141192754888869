import { SORT_BY_ALPHABET_MENU, SortByAlphabet } from '../../utils/constants';
import TopBar, { TopBarProps } from '../TopBar';

import { Box } from '@mui/material';
import CustomSelect from '../CustomSelect';
import Dimens from '../../theme/dimens';
import React, { } from 'react';
import SearchBar from '../SearchBar';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * Interface extending TopBarProps to include search and sort related props and methods.
 * 
 * @interface Props extends TopBarProps
 */
interface Props extends TopBarProps {
  /**
   * Callback function to handle changes in the sorting criteria.
   * 
   * @param {SortByAlphabet} sortBy - The selected sorting option (e.g., 'A_Z', 'Z_A').
   */
  onSortChange: (sortBy: SortByAlphabet) => void;
  /**
   * Callback function to handle changes in the search query.
   * 
   * @param {string} searchKey - The entered search term.
   */
  onSearchChange: (searchKey: string) => void;
  /**
   * Optional prop to specify the initial sorting criteria.
   * 
   * @type {string} (defaults to SortByAlphabet.A_Z)
   */
  sortBy?: string;
}

/**
 * SearchAndSortTopBar component combining a TopBar with search and sorting functionality.
 * 
 * This component renders a TopBar along with a custom select for sorting options and a 
 * search bar. It accepts props to configure the TopBar, handle sort changes, handle search 
 * changes, and set the initial sorting criteria. 
 *  
 * @param {Props} props - Component properties defining TopBar options, sort/search callbacks, and initial sort.
 * 
 * @returns {JSX.Element} - The rendered SearchAndSortTopBar component.
 */
const SearchAndSortTopBar: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Box sx={styles.container}>
      <TopBar {...props} />
      <Box sx={styles.sortFilterWrapper}>
        <CustomSelect
          hasBackground
          showCheckedIcon
          menu={SORT_BY_ALPHABET_MENU}
          value={props.sortBy || SortByAlphabet.A_Z}
          onChange={(e) => props.onSortChange(e.target.value as SortByAlphabet)}
        />
        <Box width={Dimens.spacing.lg} />
        <SearchBar handleChange={props.onSearchChange} placeholder={t('search')} longWidth={true} />
      </Box>
    </Box>
  );
};

export default SearchAndSortTopBar;
