import { AppFeature, UserTableHeader } from '../../../utils/constants';
import { IconButton, TableCell, TableRow, Typography } from '@mui/material';

import DotsVerticalIcon from '../../../components/CustomIcons/DotsVerticalIcon';
import LoginUtil from '../../../utils/login-util';
import MenuData from '../../../types/ui/menu-data';
import MenuSelectDialog from '../../../components/MenuSelectDialog';
import React, { } from 'react';
import { User } from '../../../types/user';
import UserCard from '../UserCard';
import Util from '../../../utils/util';
import { getUserListSubMenu } from '../../../utils/ui-constants';
import useStyles from './styles';

interface Props {
  user: User;
  columns: Array<MenuData>;
  onClick: () => void;
  handleSubMenuItemClick: (user: User, item: MenuData) => void;
}

/**
 * Renders a table row for the user list, displaying user information and options menu.
 *
 * @param {Props} props - Component props containing user data and click handler.
 * 
 * @returns JSX element representing the user list table row.
 */
const UserListTableRow: React.FC<Props> = (props: Props) => {

  const styles = useStyles(props.user.status);
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState<null | HTMLElement>(null);

  /**
   * Extracts department name from the user object based on default department ID.
   *
   * @param {User} user - User object containing department data.
   * 
   * @returns {string} The name of the user's default department, or an empty string if not found.
   */
  const getDeptNameById = (user: User): string => {

    return user.departments?.find(dept => dept.id === user.defaultDepartmentId)?.name ?? '';
  }

  /**
   * Checks if a specific column is visible based on its ID.
   *
   * @param {string} columnId - The ID of the column to check.
   * 
   * @returns {boolean} True if the column is visible, false otherwise.
   */
  const isColumnVisible = (columnId: string): boolean => {
    const column = props.columns.find(item => item.id === columnId);

    return (column?.isChecked ?? false);
  }

  /**
   * Handles the click event on a sub-menu item.
   *
   * @param {React.MouseEvent<HTMLButtonElement, MouseEvent>} event - The click event object.
   * 
   * Prevents event propagation and sets the anchor element for the sub-menu.
   */
  const onSubMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation();
    setSubMenuAnchorEl(event.currentTarget);
  }

  /**
   * Handles the click event on a sub-menu option.
   *
   * @param {MenuData} menu - The data object representing the clicked sub-menu option.
   */
  const handleSubMenuItemClick = (menu: MenuData) => {
    setSubMenuAnchorEl(null);
    props.handleSubMenuItemClick(props.user, menu);
  }

  return (
    <>
      <TableRow onClick={props.onClick} sx={styles.tableRow}>
        <TableCell>
          <UserCard user={props.user} />
        </TableCell>
        {isColumnVisible(UserTableHeader.UserType) &&
          <TableCell><Typography variant='p1'>{props.user.type}</Typography></TableCell>
        }
        {isColumnVisible(UserTableHeader.Department) &&
          <TableCell><Typography variant='p1'>{getDeptNameById(props.user)}</Typography></TableCell>
        }
        {isColumnVisible(UserTableHeader.Position) &&
          <TableCell><Typography variant='p1'>{props.user.position}</Typography></TableCell>
        }
        {isColumnVisible(UserTableHeader.Invited) &&
          <TableCell>
            <Typography variant='p1'>
              {Util.formatUTCtoLocal(props.user.invited)}
            </Typography>
          </TableCell>
        }
        {LoginUtil.hasPermission(AppFeature.ManageUser) &&
        <TableCell sx={styles.optionMenuBtn} align='right'>
          <IconButton onClick={onSubMenuClick}><DotsVerticalIcon /></IconButton>
        </TableCell>
        }
      </TableRow>
      <MenuSelectDialog
        anchorEl={subMenuAnchorEl}
        open={Boolean(subMenuAnchorEl)}
        onClose={() => setSubMenuAnchorEl(null)}
        menuList={getUserListSubMenu(props.user)}
        onMenuItemSelected={handleSubMenuItemClick}
      />
    </>
  );
};

export default UserListTableRow;
