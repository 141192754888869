import Dimens from '../../../../theme/dimens';
import ThemeUtil from '../../../../theme/theme-util';
import { useTheme } from '@mui/material';

/**
 * useStyles custom hook generates MUI theme dependent styles for the ChatVoiceRecorder component.
 * 
 * @returns An object containing the styled classes.
 */
const useStyles = (isUrgent = false, isRecording = false) => {

	const { mini, xxs, xs, sm, base, lg, section } = Dimens.spacing;
	const theme = useTheme();

	const styles = {
		footer: {
			display: 'flex',
			flexDirection: 'column',
			borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
			p: section,
			[theme.breakpoints.down('sm')]: {
				p: lg
			}
		},
		msgLayout: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			alignItems: 'center',
			width: '100%'
		},
		previewLayout: {
			pt: lg
		},
		recorderContainer: {
			display: 'flex',
			flexDirection: 'row',
			flex: 1,
			mx: sm,
			padding: `${mini} 0 ${mini} 0`,
			borderRadius: Dimens.radius.xl,
			backgroundColor: theme.palette.background.default,
			alignItems: 'center',
			minHeight: ThemeUtil.pxToRem(40)
		},
		iconBtn: {
			background: theme.palette.background.default,
			p: sm,
			m: 0,
			radius: Dimens.radius.md,
			'svg': {
				color: isUrgent ? theme.palette.error.main : theme.palette.text.primary,
				fontSize: Dimens.icon.sm
			}
		},
		priorityBtn: {
			background: theme.palette.background.default,
			p: sm,
			mr: xxs,
			radius: Dimens.radius.md,
			'svg': {
				color: isUrgent ? theme.palette.error.main : theme.palette.text.secondary,
				fontSize: Dimens.icon.sm
			}
		},
		fab: {
			width: 'unset',
			height: 'unset',
			minHeight: 'unset',
			p: sm,
			boxShadow: 'none !important',
			'&.MuiFab-secondary': {
				color: theme.palette.error.main
			},
			'svg': {
				fontSize: Dimens.icon.sm
			}
		},
		audioPlayer: {
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			ml: xxs,
		},
		timeInfo: {
			textAlign: 'center',
			ml: lg,
			mr: isRecording ? lg : 0
		},
		slider: {
			display: 'flex',
			flex: 1,
			ml: isRecording ? lg : xs,
			'& .MuiSlider-rail': {
        opacity: 0.5
      }
		}
	};

	return styles;
};

export default useStyles;