import Dimens from '../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the SiteScreen component.
 * 
 * @returns {object} - An object containing styles for the SiteScreen component.
 */
const useStyles = () => {

  const theme = useTheme();
  const {xs, md, lg, section } = Dimens.spacing;

  const styles = {
    container: {
      bgcolor: theme.palette.background.paper,
      height: 'inherit',
      display: 'flex',
      flex: 1,
      flexDirection: 'column'
    },
    contentWrapper: {
      overflowY: 'auto',
      m: xs,
    },
    content: {
      m: 0,
      p: 0
    },
    alertWrapper: {
      p: `${lg} ${section} 0 ${section}`
    }
  };

  return styles;
};

export default useStyles;