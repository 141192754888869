import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * This hook creates a set of styles for the AlertDialogLarge component using Material UI's theme.
 * It utilizes breakpoints to adjust styles for different screen sizes.
 */
const useStyles = () => {

  const { lg, xl, container } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    dialog: {
      '& .MuiDialog-container': {
        [theme.breakpoints.down('sm')]: {
          alignItems: 'end'
        }
      },
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        maxWidth: '100%',
        width: Dimens.alertDialogLargeWidth.lg,
        p: container,
        textAlign: 'center',
        [theme.breakpoints.down('md')]: {
          width: Dimens.alertDialogLargeWidth.md
        },
        [theme.breakpoints.down('sm')]: {
          width: '100%',
          height: Dimens.bottomDialogSize,
          minHeight: Dimens.bottomDialogSize,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          justifyContent: 'space-between',
          p: xl
        }
      },
      '& .MuiDialogContent-root': {
        p: 0,
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
          flex: 'none'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        alignItems: 'center',
        justifyContent: 'center',
        'button': {
          minWidth: Dimens.buttonMinWidth
        },
        [theme.breakpoints.down('sm')]: {
          flex: 1,
          flexDirection: 'column',
          'button': {
            width: '100%'
          }
        },
        '& .MuiButton-colorSecondary': {
          [theme.breakpoints.down('sm')]: {
            mb: Dimens.spacing.xs,
            ml: 0
          }
        }
      }
    },
    header: {
      alignItems: 'end',
      position: 'absolute',
      right: 0,
      top: 0,
      p: lg,
      '& button': {
        p: 0
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    headerImage: {
      pb: lg,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    message: {
      pb: lg,
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },
    title: {
      pb: lg,
      fontWeight: Dimens.fontWeight.bold,
      [theme.breakpoints.down('sm')]: {
        p: `0 ${lg} ${lg} ${lg}`
      }
    }
  };

  return styles;
};

export default useStyles;
