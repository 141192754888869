import { ActualSessionStatus, SESSION_DEBOUNCE_TIME, SESSION_PROMPT_TIME_OUT, SESSION_TIME_OUT } from '../utils/constants';
import { useEffect, useState } from 'react';

import LoginUtil from '../utils/login-util';
import { differenceInMilliseconds } from 'date-fns';
import { useIdleTimer } from 'react-idle-timer';
import { useInboxApi } from '../data/inbox/api';
import { useSignInApi } from '../data/sign-in/api';

/**
 * Custom hook for managing user session and idle timeouts.
 *
 * This hook provides functionalities for handling user sessions and idle timeouts within the application.
 */
export function useSessionHandler() {

  const inboxApi = useInboxApi();
  const signInApi = useSignInApi();
  const [showTimeOutDialog, setShowTimeOutDialog] = useState<boolean>(false);

  /**
   * Initializes session management and idle timer.
   * 1. Checks if the last active time exceeds the session timeout. If so, handles user inactivity.
   * 2. Refreshes the token if the user is active within the session timeout.
   * 3. Saves the last active time on window unload.
   * 4. Sets up event listeners for user inactivity and activity.
   */
  useEffect(() => {

    const lastActiveTime: Date | undefined = LoginUtil.getLastActiveTime();
    if (lastActiveTime && differenceInMilliseconds(new Date(), lastActiveTime) > SESSION_TIME_OUT) {
      handleOnUserIdle();
    } else {
      signInApi.refreshToken();
    }

    const saveLastActiveTime = () => {
      if (LoginUtil.getBearerToken()) {
        LoginUtil.saveLastActiveTime(idleTimer.getLastActiveTime() || new Date());
      }
    }
  
    window.addEventListener('beforeunload', saveLastActiveTime);
  
    return () => {
      window.removeEventListener('beforeunload', saveLastActiveTime);
    }
  }, []);

  /**
   * Handles user inactivity prompt.
   */
  const onPrompt = () => {
    setShowTimeOutDialog(true);
  }

  /**
   * Resets the idle timer on user activity.
   */
  const onActive = () => {
    setShowTimeOutDialog(false);
  }

  /**
   * Resets the idle timer.
   */
  const resetTimer = () => {
    idleTimer.activate();
  }

  /**
   * Handles the user becoming idle for the defined session timeout duration.
   *
   * This function is called by `useIdleTimer` when the user is idle for the specified
   * timeout period. It initiates session expiration logic:
   *  - Calls `performSignOut` from `useSignInApi` to log out the user from the server (if applicable).
   *  - Clears any local session details to avoid unauthorized access.
   *  - Redirects the user to the login page.
   */
  const handleOnUserIdle = (isExpired?: boolean) => {
    inboxApi.updateChatSessionStatus(ActualSessionStatus.Offline);
    signInApi.performSignOut(Boolean(isExpired));
  }

  /**
   * Sets the timeout duration to `SESSION_TIME_OUT`.
   * Calls `handleOnUserIdle` when the user is idle for the timeout.
   * Uses `SESSION_DEBOUNCE_TIME` as a debounce value to prevent immediate actions after inactivity.
   */
  const idleTimer = useIdleTimer({
    crossTab: true,
    timeout: SESSION_TIME_OUT,
    onIdle: () => handleOnUserIdle(true),
    promptBeforeIdle: SESSION_PROMPT_TIME_OUT,
    onPrompt: onPrompt,
    debounce: SESSION_DEBOUNCE_TIME,
    onActive
  });

  return {
    showTimeOutDialog,
    resetTimer,
    handleOnUserIdle
  }

}
