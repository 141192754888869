import Dimens from '../../../theme/dimens';
import { useTheme } from '@mui/material';

/**
 * useStyles Hook for InboxListScreen Styles
 * 
 * This hook defines the styles used by the InboxListScreen component.
 * It accesses theme and spacing values from Dimens.
 */
const useStyles = () => {

  const { lg, xl } = Dimens.spacing;
  const theme = useTheme();

  const styles = {
    wrapper: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      height: 'inherit',
      position: 'relative'
    },
    innerWrapper: {
      flex: 1,
      bgcolor: theme.palette.background.paper,
      overflowY: 'auto',
      display: 'flex'
    },
    fab: {
      position: 'absolute',
      boxShadow: 'none',
      right: 0,
      bottom: 0,
      m: lg,
      '.MuiSvgIcon-root': {
        fontSize: Dimens.icon.lg
      }
    },
    list: {
      p: `0 0 ${xl} 0`,
      flex: 1,
      [theme.breakpoints.down('sm')]: {
        p: `0 0 ${lg} 0`,
      }
    }
  };

  return styles;
};

export default useStyles;