import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Custom hook to generate styles for the CreateDepartmentDialog component.
 *
 * @param {boolean} showDetailForm (optional) Flag indicating whether to show the detail form section. Defaults to true.
 * 
 * @returns {Object} An object containing the defined styles.
 */
const useStyles = (showDetailForm = true) => {

  const { base, lg, xl } = Dimens.spacing;
  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    ...commonStyles,
    dialog: {
      '& .MuiDialog-paper': {
        backgroundColor: theme.palette.background.paper,
        backgroundImage: 'none',
        m: 0,
        borderRadius: Dimens.radius.md,
        minWidth: Dimens.alertDialogLargeWidth.lg,
        p: `${xl} ${xl} ${lg} ${xl}`,
        [theme.breakpoints.down('md')]: {
          minWidth: Dimens.alertDialogLargeWidth.md
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: Dimens.radius.md,
          minWidth: '90%',
          height: '80%',
          minHeight: '80%',
          justifyContent: 'center',
          p: lg
        }
      },
      '& .MuiDialogContent-root': {
        ...commonStyles.noScrollBar,
        p: 0,
        minHeight: Dimens.dialogContentMaxHeight,
        maxHeight: Dimens.dialogContentMaxHeight,
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
          minHeight: 'auto',
          maxHeight: 'none'
        }
      },
      '& .MuiDialogActions-root': {
        p: 0,
        pt: lg,
        borderTop: `${Dimens.menuBorderWidth} solid ${theme.palette.background.default}`,
        alignItems: 'center',
        justifyContent: 'end',
        '& .MuiButton-colorPrimary': {
          [theme.breakpoints.down('sm')]: {
            margin: 0,
            flex: 1
          }
        }
      }
    },
    header: {
      justifyContent: 'space-between',
      alignItems: 'start',
      display: 'flex',
      flexDirection: 'row',
      flex: 1,
      pb: lg,
      '& button': {
        p: 0
      },
      [theme.breakpoints.down('sm')]: {
        pb: base,
        flex: 'none'
      }
    },
    closeIcon: {
      p: 0,
      color: theme.palette.text.primary,
      fontSize: Dimens.icon.sm
    },
    title: {
      fontWeight: Dimens.fontWeight.bold
    },
    form: {
      flex: 1,
      justifyContent: 'space-between',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    },
    contentLeft: {
      flex: 0.5,
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.down('md')]: {
        flex: 1,
        display: showDetailForm ? 'flex' : 'none'
      }
    },
    contentRight: {
      flex: 0.5,
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        flex: 1,
        display: showDetailForm ? 'none' : 'flex'
      }
    },
    divider: {
      borderLeftWidth: Dimens.menuBorderWidth,
      borderBottomWidth: 0,
      mx: xl,
      borderColor: theme.palette.background.default,
      [theme.breakpoints.down('md')]: {
        display: 'none'
      }
    },
    nextBtn: {
      display: 'none',
      [theme.breakpoints.down('md')]: {
        display: showDetailForm ? 'flex' : 'none'
      }
    },
    saveBtn: {
      display: 'flex',
      [theme.breakpoints.down('md')]: {
        display: showDetailForm ? 'none' : 'flex'
      }
    }
  };

  return styles;
};

export default useStyles;
