import { Box, InputAdornment, useTheme } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { RESEND_EMAIL, SIGN_IN_INVITE, SIGN_IN_PASSWORD } from '../../utils/constants';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import APP_NAV from '../../routes/app-nav';
import AlertDialogLarge from '../../components/AlertDialogLarge';
import Challenge from '../../types/challenge';
import CheckFilledIcon from '../../components/CustomIcons/CheckFilledIcon';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import EmailImageDark from '../../assets/sign_in_email_dark.svg';
import EmailImageLight from '../../assets/sign_in_email_light.svg';
import ResendEmailImageDark from '../../assets/sign_in_resend_email_dark.svg';
import ResendEmailImageLight from '../../assets/sign_in_resend_email_light.svg';
import { SessionExpirationContext } from '../../store/session-expiration-provider';
import SessionTimeOutDark from '../../assets/session_time_out_dark.svg';
import SessionTimeOutLight from '../../assets/session_time_out_light.svg';
import SignInLayout from '../../layouts/SignInLayout';
import SignInOptionDialog from './SignInOptionDialog';
import SignInRequest from '../../types/sign-in-request';
import ThemeUtil from '../../theme/theme-util';
import Util from '../../utils/util';
import { signInEmailSchema } from '../../utils/validation-schema';
import { useSignInApi } from '../../data/sign-in/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

/**
 * SignInEmailScreen Component
 *
 * This component renders the sign-in screen with a form for entering an email address.
 * It leverages the SignInLayout component for a consistent layout.
 *
 * @returns {JSX.Element} - The rendered JSX element for the sign-in screen.
 */
const SignInEmailScreen: React.FC = () => {

  const styles = useStyles();
  const isLightTheme = ThemeUtil.isLightTheme(useTheme());
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const signInApi = useSignInApi();
  const initialValues: SignInRequest = {
    loginId: '',
    clientId: ''
  };
  const [showResentAlert, setShowResendAlert] = useState<boolean>(false);
  const [showSignInOption, setShowSignInOption] = useState<boolean>(false);
  const { setSessionExpired } = useContext(SessionExpirationContext);
  const [showSessionExpiredDialog, setShowSessionExpiredDialog] = useState<boolean>(Boolean(location.state?.isSessionTimeOut));

  // Handle challenge list updates from the signInApi state
  useEffect(() => {
    if (location.state?.isSessionTimeOut) {
      window.history.replaceState(undefined, '');
    }
    setSessionExpired(false);
    manageChallenge(signInApi.state.challengeList);
  }, [signInApi.state.challengeList]);

  /**
   * Handles challenge logic based on the challenge list.
   * 
   * This function analyzes the challenge list and displays appropriate alerts or redirects based on the challenge type.
   * 
   * @param {Array<Challenge>} challengeList - List of challenges from the signInApi state.
   * @returns {void}
   */
  const manageChallenge = (challengeList: Array<Challenge>) => {
    if (!Util.isArrayEmpty(signInApi.state.challengeList)) {
      if (challengeList.length === 1) {
        const challenge = challengeList[0];
        switch (challenge.type) {
          case RESEND_EMAIL:
            setShowResendAlert(true);
            break;
          case SIGN_IN_INVITE:
          case SIGN_IN_PASSWORD:
            onSignOptionSelected(challenge.type);
            break;
          default:
            break;
        }
      } else {
        setShowSignInOption(true);
      }
    }
  }

  /**
   * Handles selection of a sign-in option.
   * 
   * This function is called when a user selects an option from the SignInOptionDialog.
   * It hides alerts, filters the challenge list, and redirects or sends emails based on the chosen option.
   * 
   * @param {string} option - The selected sign-in option (e.g., SIGN_IN_PASSWORD, RESEND_EMAIL).
   * @returns {void}
   */
  const onSignOptionSelected = (option: string) => {
    setShowResendAlert(false);
    setShowSignInOption(false);
    const challenge = signInApi.state.challengeList.filter(
      (challenge: Challenge) => challenge.type === option
    )?.[0];
    if (challenge) {
      if (option === SIGN_IN_PASSWORD) {
        navigate(APP_NAV.SIGN_IN_PASSWORD, {
          state: {
            challengePath: challenge._links.next.href,
            loginId: location.state.loginId
          }
        });
      } else if (option === SIGN_IN_INVITE || option === RESEND_EMAIL) {
        signInApi.sendEmail(challenge._links.proof.href);
      }
    }

  }

  /**
   * Handles form submission.
   * 
   * This function is called when the user submits the email address form.
   * It updates the location state with the email and calls the signInApi.performSignIn function.
   * 
   * @param {SignInRequest} value - The object containing the submitted form values (email address).
   * @returns {void}
   */
  const onSubmit = (value: SignInRequest) => {
    location.state = {
      loginId: value.loginId
    };
    signInApi.performSignIn(value);
  }

  /**
   * Handles sign up click action.
   *
   * This function is called when the user clicks on the sign up link.
   * Currently, this functionality is disabled.
   *
   * @returns {void}
   */
  const onFooterClick = () => {
    // TODO: Implement this once direct sign-up functionality is added.
  }

  return (
    <SignInLayout
      error={signInApi.state.apiStatus?.error}
      showProgressBar={signInApi.state.apiStatus?.isLoading}
      headerTitle={t('signIn')}
      headerDesc={t('signInDesc')}
      footerCaption={t('signInFtrCaption')}
      footerAction={t('signUp')}
      hideFooter={true}
      image={isLightTheme ? EmailImageLight : EmailImageDark}
      onFooterClick={onFooterClick}
    >
      <Formik
        validateOnMount
        validationSchema={signInEmailSchema}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ dirty, isValid }) => (
          <Form>
            <Field
              name='loginId'
              placeholder={t('emailAddress')}
              component={CustomInput}
              endAdornment={(dirty && isValid) &&
                <InputAdornment position='end'>
                  <CheckFilledIcon sx={styles.icon} />
                </InputAdornment>
              } />
            <Box sx={styles.spacer} />
            <CustomButton
              type='submit'
              title={t('continue')}
              color='primary'
              disabled={!dirty || !isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <AlertDialogLarge
        open={showResentAlert}
        title={t('resendEmailTitle')}
        message={`${t('resendEmailMsg1')} ${location.state?.loginId || t('yourEmail')} ${t('resendEmailMsg2')}`}
        titleIcon={isLightTheme ? ResendEmailImageLight : ResendEmailImageDark}
        secondaryLabel={t('contactSupport')}
        onSecondaryAction={() => setShowResendAlert(false)}
        primaryLabel={t('resendEmail')}
        onPrimaryAction={() => onSignOptionSelected(RESEND_EMAIL)}
        onClose={() => setShowResendAlert(false)}
        footerCaption={t('checkSpamMsg')}
      />
      <AlertDialogLarge
        open={showSessionExpiredDialog}
        title={t('sessionExpired')}
        message={t('sessionExpiredMsg')}
        titleIcon={isLightTheme ? SessionTimeOutLight : SessionTimeOutDark}
        primaryLabel={t('signIn')}
        onPrimaryAction={() => setShowSessionExpiredDialog(false)}
        onClose={() => setShowSessionExpiredDialog(false)}
      />
      <SignInOptionDialog
        open={showSignInOption}
        onSignInOptionSelected={option => onSignOptionSelected(option)}
        onClose={() => setShowSignInOption(false)}
      />
    </SignInLayout>
  );
};

export default SignInEmailScreen;
