import {
	API_FAILURE,
	CHAT_HISTORY_SUCCESS,
	CHAT_SEND_API,
	CHAT_SEND_FAILED,
	CHAT_SEND_SUCCESS,
	FETCH_CHAT_HISTORY,
	FETCH_INTERACTION_HISTORY,
	FETCH_MESSAGE_INFO,
	FETCH_UNDELIVERED_INTERACTION_HISTORY,
	INTERACTION_HISTORY_SUCCESS,
	InboxActionTypes,
	MARK_CHAT_AS_DELIVERED_SUCCESS,
	MARK_CHAT_AS_READ,
	MARK_CHAT_AS_READ_SUCCESS,
	MESSAGE_INFO_SUCCESS,
	RESET_INBOX_STATE,
	UNDELIVERED_INTERACTION_HISTORY_SUCCESS,
	UPDATE_MESSAGE_ATTACHMENT,
	UPDATE_SESSION_STATUS,
	UPDATE_SESSION_STATUS_SUCCESS
} from './action-types';

import ChatUtil from '../../utils/chat-util';
import InboxState from '../../types/states/inbox-state';

/**
 * Initial State for Inbox Data
 * 
 * This object defines the initial state of the inbox reducer.
 * It includes properties for the message list and API call status.
 */
export const inboxInitialState: InboxState = {
	messageList: [],
	apiStatus: undefined
};

/**
 * Inbox Reducer Function
 * 
 * This function takes the current state and an action object as input.
 * It handles different action types to update the state accordingly.
 * 
 * @param {InboxState} state - Current state of the inbox data.
 * @param {InboxActionTypes} action - Action object dispatched by the application.
 * @returns {InboxState} - The updated state of the inbox data.
 */
const inboxReducer = (state: InboxState, action: InboxActionTypes): InboxState => {
	switch (action.type) {
		case FETCH_INTERACTION_HISTORY:
		case FETCH_UNDELIVERED_INTERACTION_HISTORY:
		case FETCH_CHAT_HISTORY:
		case MARK_CHAT_AS_READ:
		case UPDATE_SESSION_STATUS:
		case FETCH_MESSAGE_INFO:
			return {
				...state,
				apiStatus: {
					task: action.type,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case UPDATE_SESSION_STATUS_SUCCESS:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case INTERACTION_HISTORY_SUCCESS:
			return {
				...state,
				messageList: action.payload,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case CHAT_HISTORY_SUCCESS:
			return {
				...state,
				messageList: ChatUtil.getUpdatedConversationList(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case CHAT_SEND_API:
			return {
				...state,
				messageList: ChatUtil.addMessageToConversation(state.messageList, action.payload),
				apiStatus: {
					task: CHAT_SEND_API,
					isLoading: true,
					data: undefined,
					error: undefined,
					isCompleted: false
				}
			};
		case CHAT_SEND_SUCCESS:
			return {
				...state,
				messageList: ChatUtil.addMessageToConversation(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case CHAT_SEND_FAILED:
			return {
				...state,
				messageList: ChatUtil.addMessageToConversation(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload.error,
					error: undefined,
					isCompleted: true
				}
			};
		case MARK_CHAT_AS_READ_SUCCESS:
			return {
				...state,
				messageList: ChatUtil.updateMarkAsReadStatus(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case MESSAGE_INFO_SUCCESS:
			return {
				...state,
				messageList: ChatUtil.updateMessageInfo(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case MARK_CHAT_AS_DELIVERED_SUCCESS: 
			return {
				...state,
				messageList: ChatUtil.updateMarkAsDeliveredStatus(state.messageList, action.payload),
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true,
				},
			};		
		case UNDELIVERED_INTERACTION_HISTORY_SUCCESS:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: undefined,
					isCompleted: true
				}
			};			
		case UPDATE_MESSAGE_ATTACHMENT:
			return {
				...state,
				messageList: ChatUtil.updateMessageAttachment(state.messageList, action.payload),
				apiStatus: {
					task: UPDATE_MESSAGE_ATTACHMENT,
					isLoading: false,
					data: action.payload,
					error: undefined,
					isCompleted: true
				}
			};
		case API_FAILURE:
			return {
				...state,
				apiStatus: {
					...state.apiStatus,
					isLoading: false,
					data: undefined,
					error: action.payload.message,
					isCompleted: true
				}
			};
		case RESET_INBOX_STATE:
			return {
			...inboxInitialState
			};
		default:
			return state;
	}
};

export default inboxReducer;