import { Box, Grid } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';

import CircularProgressBar from '../../../../components/CircularProgressBar';
import CustomAutocomplete from '../../../../components/CustomAutocomplete';
import CustomButton from '../../../../components/CustomButton';
import CustomInput from '../../../../components/CustomInput';
import CustomInputSelect from '../../../../components/CustomInputSelect';
import InlineAlert from '../../../../components/InlineAlert';
import MenuData from '../../../../types/ui/menu-data';
import { UPDATE_USER_DETAIL } from '../../../../data/user/action-types';
import { User } from '../../../../types/user';
import { UserProfileStatus } from '../../../../utils/constants';
import Util from '../../../../utils/util';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';
import { useUserApi } from '../../../../data/user/api';
import { useUserTypeApi } from '../../../../data/user-type/api';
import { userSchema } from '../../../../utils/validation-schema';

interface Props {
  user: User;
  onClose: () => void;
}

/**
 * ProfileTab component for editing user profile details.
 *
 * @param {Props} props - Component props containing user data.
 * 
 * @returns {JSX.Element} The rendered component.
 */
const ProfileTab: React.FC<Props> = (props: Props) => {

  const styles = useStyles();
  const { t } = useTranslation();
  const [user, setUser] = useState<User>(props.user);
  const userTypeApi = useUserTypeApi();
  const userApi = useUserApi();
  const apiStatus = userApi.state.user.apiStatus;

  useEffect(() => {
    if (Util.isArrayEmpty(userTypeApi.state.userType.userTypeList)) { 
      userTypeApi.fetchUserTypeList();
    }
  }, []);
  

  // Process user type list from userApi state for CustomInputSelect component
  const userTypeList: Array<MenuData> = userApi.state.userType.userTypeList.map(userType => ({
    id: userType.userType,
    value: userType.userType
  }));

  /**
   * Handles form submission by calling updateUserDetail API and updating local state.
   *
   * @param {User} values - User data from the submitted form.
   */
  const onSubmit = (values: User) => {
    userApi.updateUserDetail(values).then((data) => {
      if (data) {
        props.onClose();
      }
    });
  }

  return (
    <Grid sx={styles.wrapper}>
      <Formik
        enableReinitialize
        validateOnMount
        validationSchema={userSchema}
        initialValues={user}
        onSubmit={values => onSubmit(values)}>
        {({ dirty, isValid }) => (
          <Form style={styles.form as React.CSSProperties}>
            <Box sx={styles.formInnerWrapper}>
              <InlineAlert message={Util.getApiError([UPDATE_USER_DETAIL], apiStatus)} />
              <Field
                name='name'
                label={t('name')}
                placeholder={t('name')}
                component={CustomInput}
              />             
              <Field
                name='email'
                label={t('email')}
                placeholder={t('email')}
                component={CustomInput}
                readOnly={user.status !== UserProfileStatus.Pending}
              />
               <Field
                name='type'
                label={t('userType')}
                menu={userTypeList}
                component={CustomInputSelect}
                hasBottomSpacing
              />
              <Field
                name='position'
                label={t('position')}
                placeholder={t('position')}
                component={CustomInput}
              />
              <Field
                name='about'
                label={t('aboutYou')}
                subLabel={t('optional')}
                placeholder={t('aboutYouPlaceholder')}
                multiline
                component={CustomInput}
              />
              <Field
                name='departments'
                label={t('departments')}
                placeholder={t('findDepartments')}
                component={CustomAutocomplete}
                noOptionsText={t('noDepts')}
                multiple
                defaultOptionId={user.defaultDepartmentId}
                menu={userApi.state.department.departmentList}
              />
            </Box>
            <CustomButton
              type='submit'
              title={t('save')}
              color='primary'
              disabled={!dirty || !isValid}
              fullWidth
            />
          </Form>
        )}
      </Formik>
      <CircularProgressBar show={Util.isApiLoading([UPDATE_USER_DETAIL], apiStatus)} />
    </Grid>
  );
};

export default ProfileTab;