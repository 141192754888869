import Dimens from '../../../theme/dimens';
import useCommonStyles from '../../../theme/use-common-styles';
import { useTheme } from '@mui/material';

/**
 * Styles for the VPermissionTableHeader component.
 * Defines the styles for the table row header, including background color, alignment for the last cell, and icon styling.
 *
 * @returns {Object} The styles object.
 */
const useStyles = () => {

  const theme = useTheme();
  const commonStyles = useCommonStyles();

  const styles = {
    rowHeader: {
      bgcolor: theme.palette.background.default,
      '& td:last-of-type': {
        textAlign: 'right !important'
      },
      '& svg': {
        ...commonStyles.smIcon
      }      
    }, 
    toggleRow : {
      cursor: 'pointer'
    }
  };

  return styles;
};

export default useStyles;