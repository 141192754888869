import { Box, Dialog, DialogProps, IconButton, Stack, Typography } from '@mui/material';
import { ContentType, DATE_PATTERN_MMM_YYYY, DEFAULT_PAGE_SIZE, DirectoryRecordType, EntityType, GroupType, MessagePriority, SortOrder } from '../../../../utils/constants';
import React, { useEffect, useState } from 'react';

import ChatUtil from '../../../../utils/chat-util';
import ConversationData from '../../../../types/conversation-data';
import CrossIcon from '../../../../components/CustomIcons/CrossIcon';
import { DirectoryRecord } from '../../../../types/directory-record';
import { DirectorySearchRequest } from '../../../../types/directory-search-request';
import ForwardRecipientCard from './ForwardRecipientCard';
import LoginUtil from '../../../../utils/login-util';
import MessageData from '../../../../types/message-data';
import SearchBar from '../../../../components/SearchBar';
import Util from '../../../../utils/util';
import { useDirectoryApi } from '../../../../data/directory/api';
import { useInboxApi } from '../../../../data/inbox/api';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props extends DialogProps {
	conversation: ConversationData;
	messageData: MessageData;
	onClose: () => void;
}

/**
 * Renders a dialog for selecting recipients to forward a message to.
 *
 * @param {Props} props - Component props containing the message to forward, onClose callback, and dialog properties.
 * 
 * @returns JSX element representing the message forward dialog.
 */
const MessageForwardDialog: React.FC<Props> = (props: Props) => {

	const { conversation, messageData, onClose, ...rest } = props;
	const styles = useStyles();
	const { t } = useTranslation();
	const [sentList, setSentList] = useState<Array<DirectoryRecord>>([]);
	const directoryApi = useDirectoryApi();
	const inboxApi = useInboxApi();

	/**
	 * Fetches the initial list of directory records when the dialog opens.
	 *
	 * @param {boolean} isOpen - True if the dialog is open, false otherwise.
	 */
	useEffect(() => {
		if (props.open) {
			initDirectorySearch('');
		}
	}, [props.open]);

	/**
	 * Filters the directory record list to exclude the current user and group rooms the user is not a member of.
	 *
	 * @returns {Array<DirectoryRecord>} The filtered list of directory records.
	 */
	const filteredList = directoryApi.state.directory.recordList
		.filter(record => (!record.groupRoomKey || record.isMember) && (record.email !== LoginUtil.getLoginId()));

	/**
	 * Checks if the message has already been sent to the specified recipient.
	 *
	 * @param {DirectoryRecord} record - The recipient's directory record.
	 * @returns {boolean} True if the message has been sent, false otherwise.
	 */
	const isAlreadySent = (record: DirectoryRecord): boolean => {

		return sentList.some(item => (item.groupRoomKey && item.groupRoomKey === record.groupRoomKey)
			|| (item.email && item.email === record.email));
	}

	/**
	 * Generates the subtitle for a recipient card based on whether it's a user or a group room.
	 *
	 * @param {DirectoryRecord} record - The recipient's directory record.
	 * @returns {string} The subtitle for the recipient card.
	 */
	const getSubTitle = (record: DirectoryRecord) => {
		let subTitle = record.position;
		if (record.groupRoomKey) {
			subTitle = `${t('created')} ${Util.formatUTCtoLocal(record.createdAt, DATE_PATTERN_MMM_YYYY)}`
		}

		return subTitle;
	}

	/**
	 * Gets the corresponding forward content type for a given content type.
	 *
	 * @param {ContentType} contentType - The original content type.
	 * 
	 * @returns {ContentType} The forward content type.
	 */
	const getForwardContentType = (contentType: ContentType): ContentType => {
		let forwardContentType = contentType;
		// Check if the passed content type starts with 'Content'
		if (contentType.startsWith('CONTENT_')) {
			// Replace 'CONTENT_' with 'FORWARD_' to get the corresponding forward type
			forwardContentType = contentType.replace('CONTENT_', 'FORWARD_') as ContentType;
		}
		
		return forwardContentType;
	}

	/**
	 * Determines the group type of a directory record.
	 *
	 * If the record has a group room key, it searches the inbox message list for the corresponding conversation to get its group type.
	 *
	 * @param {DirectoryRecord} record The directory record to check.
	 * 
	 * @returns {GroupType | undefined} The group type of the record, or undefined if not found.
	 */
	const getGroupType = (record: DirectoryRecord) => {
		let groupType: GroupType | undefined;
		if (record.groupRoomKey) {
			const conversation = inboxApi.state.inbox.messageList.find(item => item.groupRoomKey === record.groupRoomKey);
			groupType = conversation?.groupType;
		}

		return groupType;
	}

	/**
	 * Initializes the directory search with the provided search key.
	 *
	 * @param {string} searchKey - The search keyword.
	 */
	const initDirectorySearch = (searchKey: string) => {
		const request: DirectorySearchRequest = {
			search: searchKey,
			types: [DirectoryRecordType.Group, DirectoryRecordType.User],
			filter: {
				departmentIds: null,
				siteIds: null
			},
			page: {
				pageNumber: 0,
				size: DEFAULT_PAGE_SIZE,
				sort: {
					sortBy: DirectoryRecordType.User,
					order: SortOrder.Asc
				}
			},
			isComplete: false
		};
		directoryApi.searchDirectory(request);
	}

	/**
	 * Handles the send button click event to forward the message to the selected recipient.
	 *
	 * @param {DirectoryRecord} record - The recipient's directory record.
	 */
	const onSendBtnClick = (record: DirectoryRecord) => {
		const messageData: MessageData = {
			senderName: LoginUtil.getUserFullName(),
			senderLoginId: LoginUtil.getLoginId(),
			senderEntityType: EntityType.User,
			groupRoomKey: record.groupRoomKey,
			recipientLoginId: record.email,
			recipientName: record.name,
			recipientEntityType: EntityType.User,
			contentText: props.messageData.contentText ?? '',
			contentType: getForwardContentType(props.messageData.contentType),
			priority: props.messageData.priority,
			attachmentKey: props.messageData.attachmentKey,
			recipientImageKey: record.groupRoomKey ? record.imageKey : record.image?.image,
			recipientThumbnailImageKey: record.groupRoomKey ? record.imageKey : record.image?.thumbnail
		};
		if (messageData.priority === MessagePriority.Pager) {
			if (record.groupRoomKey) {
				const groupType = getGroupType(record);
				if (groupType !== GroupType.Mixed) {
					messageData.priority = MessagePriority.Normal;
				}
			} else {
				messageData.priority = MessagePriority.Normal;
			}
		}
		inboxApi.sendChat(messageData);
		const updatedSentList = [...sentList];
		updatedSentList.push(record);
		setSentList(updatedSentList);
	}

	return (
		<Dialog {...rest} sx={styles.dialog} onClose={onClose}>
			<Stack sx={styles.header}>
				<Typography variant={'h5'} sx={styles.title}>{t('forwardTo')}</Typography>
				<IconButton onClick={onClose}>
					<CrossIcon sx={styles.closeIcon} />
				</IconButton>
			</Stack>
			<SearchBar placeholder={t('search')} handleChange={initDirectorySearch} />
			<Box sx={styles.content}>
				{filteredList.map((record, index) => (
					<ForwardRecipientCard
						key={`recipient-card-${index}`}
						title={record.name}
						subTitle={getSubTitle(record)}
						isSent={isAlreadySent(record)}
						isGroup = {Boolean(record.groupRoomKey)}
						onSendClick={() => onSendBtnClick(record)}
					/>
				))}
			</Box>
		</Dialog>
	);
};

export default MessageForwardDialog;