import Dimens from '../../../theme/dimens';
import { useTheme } from "@mui/material";

/**
 * Styles for the PermissionTableRow component.
 * Defines the styles for the checkbox element within the table cell.
 *
 * @returns {Object} The styles object.
 */
const useStyles = () => {

  const theme = useTheme();

  const styles = {
    checkbox: {
      p: 0,
      '&.Mui-disabled': {
        color: theme.palette.primary.light
      }
    }
  };

  return styles;
};

export default useStyles;