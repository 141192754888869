import { ACCEPTED_DOC_EXTENSIONS, ACCEPTED_IMAGE_EXTENSIONS, ACCEPTED_VIDEO_EXTENSIONS, AUDIO_INPUT, AttachmentType, ContentType, GroupType, MAX_FILE_SIZE, MessagePriority, PAGER_MSG_LENGTH, VIDEO_INPUT } from '../../../../utils/constants';
import { ATTACHMENT_TYPE_LIST, getMessagePriorityList } from '../../../../utils/ui-constants';
import { Box, Fab, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';

import AddIcon from '../../../../components/CustomIcons/AddIcon';
import ChatUtil from '../../../../utils/chat-util';
import ChatVoiceRecorder from '../ChatVoiceRecorder';
import ConversationData from '../../../../types/conversation-data';
import { IDocument } from '@cyntler/react-doc-viewer';
import MenuData from '../../../../types/ui/menu-data';
import MenuSelectDialog from '../../../../components/MenuSelectDialog';
import MicIcon from '../../../../components/CustomIcons/MicIcon';
import PriorityIcon from '../../../../components/CustomIcons/PriorityIcon';
import SendIcon from '../../../../components/CustomIcons/SendIcon';
import Toast from '../../../../components/Toast';
import Util from '../../../../utils/util';
import WebCamMediaCapture from '../../../../components/WebCamMediaCapture';
import useMediaDeviceAvailability from '../../../../hooks/use-media-device-availability';
import useStyles from './styles';
import { useTranslation } from 'react-i18next';

interface Props {
  resetAttachmentView: boolean;
  onFileSelected: (documentItem: Array<IDocument>) => void;
  onSendMessage: (message: string, priority: MessagePriority, file?: File) => void;
  conversation?: ConversationData;
}

/**
 * ChatFooter Component
 * 
 * This component renders the chat footer section at the bottom of a chat window.
 * It provides functionalities for composing and sending messages.
 */
const ChatFooter: React.FC<Props> = (props: Props) => {

  const { t } = useTranslation();
  const [message, setMessage] = useState<string>('');
  const [priority, setPriority] = useState<MessagePriority>(MessagePriority.Normal);
  const styles = useStyles(ChatUtil.isUrgent(priority));
  const [priorityAnchorEl, setPriorityAnchorEl] = React.useState<null | HTMLElement>(null);
  const [attachmentAnchorEl, setAttachmentAnchorEl] = React.useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [documentItem, setDocument] = useState<Array<IDocument>>([]);
  const [file, setFile] = useState<File | undefined>();
  const [toastMsg, setToastMsg] = useState<string>('');
  const [showWebCam, setShowWebCam] = useState<boolean>(false);
  const { checkDeviceAvailability } = useMediaDeviceAvailability();
  const [showVoiceRecorder, setShowVoiceRecorder] = useState<boolean>(false);

  /**
   * When user switch to a new conversation, clear the message the user already typed.
   */
  useEffect(() => {
    setMessage('');
    setPriority(MessagePriority.Normal);
    setShowVoiceRecorder(false);
  }, [props.conversation]);


  /**
   * Resets the attachment view when the `resetAttachmentView` prop changes.
   */
  useEffect(() => {
    if (props.resetAttachmentView) {
      onMediaCloseClick();
    }
  }, [props.resetAttachmentView]);

  /**
   * Handles clicks on the upload attachment button.
   * 
   * (Placeholder for future implementation)
   */
  const handleAttachmentTypeClick = (menuItem: MenuData) => {
    setAttachmentAnchorEl(null);
    if (fileInputRef.current) {
      switch (menuItem.id) {
        case AttachmentType.Camera:
          handleCameraClick();
          break;
        case AttachmentType.Photo:
          fileInputRef.current.accept = ACCEPTED_IMAGE_EXTENSIONS;
          fileInputRef.current.click();
          break;
        case AttachmentType.Video:
          fileInputRef.current.accept = ACCEPTED_VIDEO_EXTENSIONS;
          fileInputRef.current.click();
          break;
        case AttachmentType.Document:
          fileInputRef.current.accept = ACCEPTED_DOC_EXTENSIONS;
          fileInputRef.current.click();
          break;
      }
    }
  }

  /**
   * Handles the click event on the camera button.
   */
  const handleCameraClick = () => {
    checkDeviceAvailability(VIDEO_INPUT).then(isCamAvailable => {
      if (isCamAvailable) {
        setShowWebCam(true);
      } else {
        setToastMsg(t('cameraNotAvailable'));
      }
    });
  }

  /**
   * Handles the click event on the microphone button.
   *
   * Checks if the microphone is available, and if so, shows the voice recorder. Otherwise, displays a toast message indicating microphone unavailability.
   */
  const handleMicClick = async () => {
    const isMicAvailable = await checkDeviceAvailability(AUDIO_INPUT);
    if (isMicAvailable) {
      setShowVoiceRecorder(true);
    } else {
      setToastMsg(t('micNotAvailable'));
    }
  }

  /**
   * Handles the completion of media capture (image or video).
   *
   * @param {File} file - The captured file.
   */
  const onCaptureComplete = (file: File) => {
    setShowWebCam(false);
    handleFile(file);
  }

  /**
   * Handles file selection changes from the hidden file input.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} event - The file change event.
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      handleFile(selectedFile);
    } else {
      onMediaCloseClick();
    }
  };

  /**
   * Handles the captured file after media capture is complete.
   *
   * @param {File} file - The captured file object.
   */
  const handleFile = (file: File) => {
    const contentType = Util.getFileType(file.name);
    if (contentType === ContentType.ContentAudio || contentType === ContentType.ContentFile) {
      setToastMsg(t('fileNotSupported'));
      onMediaCloseClick();
    } else {
      if (file.size < MAX_FILE_SIZE) {
        const documentItem = [{
          uri: window.URL.createObjectURL(file),
          fileName: file.name
        }];
        setFile(file);
        setDocument(documentItem);
        props.onFileSelected(documentItem);
      } else {
        setToastMsg(t('fileSizeExceed'));
        onMediaCloseClick();
      }
    }
  }

  /**
   * Handles the sending of an audio file.
   *
   * Checks if the file size is within the maximum allowed limit. If so, sends the audio file using the provided `onSendMessage` callback and closes the voice recorder.
   * Otherwise, displays a toast message indicating file size exceeds the limit.
   *
   * @param {File} file The audio file to be sent.
   */
  const onSendAudio = (file: File) => {
    if (file.size < MAX_FILE_SIZE) {
      props.onSendMessage(message, priority, file);
      setShowVoiceRecorder(false);
    } else {
      setToastMsg(t('fileSizeExceed'));
      onMediaCloseClick();
    }
  }

  /**
   * Handles clicks on the priority button.
   */
  const handlePriorityChange = (menuItem: MenuData) => {
    setPriorityAnchorEl(null);
    setPriority(menuItem.id as MessagePriority);
  }

  /**
   * Handles closing the attachment selection menu and resets related state.
   */
  const onMediaCloseClick = () => {
    setFile(undefined);
    setDocument([]);
    props.onFileSelected([]);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }

  /**
   * Checks if there is an error in the message based on priority and length.
   *
   * If the message priority is not "Normal" and the message length exceeds the maximum allowed length for pager messages,
   * it checks if the conversation is a group chat and if the recipient pager ID is available. If both conditions are met,
   * an error message is returned based on the priority.
   *
   * @returns {string} The error message, or an empty string if no error is found.
   */
  const hasMessageError = () => {
    let error = '';
    if (priority !== MessagePriority.Normal && message.length > PAGER_MSG_LENGTH) {
      let canSendToPager = false;
      if (ChatUtil.isGroupChat(props.conversation)) {
        canSendToPager = props.conversation?.groupType === GroupType.Mixed;
      } else {
        canSendToPager = Boolean(props.conversation?.recipientHasPager);
      }
      if (canSendToPager) {
        error = (priority === MessagePriority.Urgent ? t('urgent') : t('pager')) + ' ' + t('charCountExceed', {
          count: PAGER_MSG_LENGTH,
          field: t('message')
        });
      }
    }

    return error;
  }

  /**
   * Handles the Enter key press event on the message input field.
   *
   * @param {React.KeyboardEvent<HTMLDivElement>} event - The keyboard event.
   */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      sendMessage();
    }
  };

  /**
   * Handles sending the message with the specified priority and attachment.
   */
  const sendMessage = () => {
    if (message.trim() || file) {
      props.onSendMessage(message.trim(), priority, file);
      setMessage('');
      onMediaCloseClick();
    }
  }

  return (
    <>
      {
        showVoiceRecorder ? (
          <ChatVoiceRecorder
            conversation={props.conversation}
            priority={priority}
            onPriorityChange={setPriority}
            onSendAudio={onSendAudio}
            onClose={() => setShowVoiceRecorder(false)} />
        ) : (
          <Box sx={styles.footer}>
            <Box sx={styles.msgLayout}>
              <Fab color='secondary' sx={styles.fab} onClick={(event) => setAttachmentAnchorEl(event.currentTarget)}>
                <AddIcon />
              </Fab>
              <TextField
                type='text'
                multiline
                maxRows={4}
                placeholder={t('messageTxt')}
                sx={styles.textField}
                value={message}
                onChange={e => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                InputProps={{
                  sx: styles.outerInput,
                  endAdornment:
                    <InputAdornment position='end'>
                      <IconButton
                        sx={styles.iconBtn}
                        onClick={(event) => setPriorityAnchorEl(event.currentTarget)}
                        edge='end'
                      >
                        <PriorityIcon />
                      </IconButton>
                    </InputAdornment>
                }}
                inputProps={{ sx: styles.innerInput }}
              />
              {
                (message.trim() || !Util.isArrayEmpty(documentItem)) ? (
                  <Fab
                    color={ChatUtil.isUrgent(priority) ? 'error' : 'primary'}
                    sx={styles.fab}
                    disabled={Boolean(hasMessageError())}
                    onClick={sendMessage}
                  >
                    <SendIcon />
                  </Fab>
                ) : (
                  <Fab color='secondary' sx={styles.fab} onClick={handleMicClick}>
                    <MicIcon />
                  </Fab>
                )
              }
            </Box>
            {hasMessageError() &&
              <Typography variant='footer' sx={styles.msgError}>{hasMessageError()}</Typography>
            }
            <input type='file' ref={fileInputRef} hidden onChange={handleFileChange} />
            <MenuSelectDialog
              anchorEl={priorityAnchorEl}
              open={Boolean(priorityAnchorEl)}
              onClose={() => setPriorityAnchorEl(null)}
              menuList={getMessagePriorityList(props.conversation?.groupType === GroupType.Mixed)}
              onMenuItemSelected={handlePriorityChange}
              isInboxMenu= {true}
              style= {styles.priorityMenu}
            />
            <MenuSelectDialog
              anchorEl={attachmentAnchorEl}
              open={Boolean(attachmentAnchorEl)}
              onClose={() => setAttachmentAnchorEl(null)}
              menuList={ATTACHMENT_TYPE_LIST}
              onMenuItemSelected={handleAttachmentTypeClick}
              isInboxMenu= {true}
              style= {styles.attachmentMenu}
            />
            {showWebCam &&
              <WebCamMediaCapture onCaptureComplete={onCaptureComplete} onClose={() => setShowWebCam(false)} />
            }
            <Toast open={Boolean(toastMsg)} severity='error' title={toastMsg} onClose={() => setToastMsg('')} />
          </Box>
        )
      }
    </>
  );
};

export default ChatFooter;
