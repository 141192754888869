import { ActualSessionStatus, AssignedSessionStatus, ChatRoomKey, DATE_PATTERN_DD_MMM, DATE_PATTERN_DD_MMM_YYYY, InteractionType, MessagePriority, TIME_PATTERN_HH_MM_AAA } from './constants';
import { isThisYear, isToday, isYesterday } from 'date-fns';

import ChatHistoryDispatchPayload from '../types/chat-history-dispatch-payload';
import ChatInteractionMarkRequest from '../types/chat-interaction-mark-request';
import ConversationData from '../types/conversation-data';
import LoginUtil from './login-util';
import MarkedInteractionData from '../types/marked-interaction-data';
import MessageData from '../types/message-data';
import MessageInfo from '../types/message-info';
import Util from './util';
import { t } from 'i18next';

/**
 * ChatUtil Class
 *
 * This class provides various helper functions for chat-related tasks
 * such as formatting dates and times, checking conversation types,
 * and handling user login information.
 */
export default class ChatUtil {

  /**
   * getChatFormatDate function
   *
   * This function formats a date string for chat display based on its relative
   * position to the current date.
   *
   * @param {string} dateStr The date string in UTC format.
   * @returns {string} The formatted date string for chat display.
   */
  public static getChatFormatDate(dateStr: string): string {

    let formattedDate = '';
    const localDate: Date = Util.UTCtoLocalTime(dateStr);
    if (isToday(localDate)) {
      formattedDate = Util.formatUTCtoLocal(dateStr, TIME_PATTERN_HH_MM_AAA);
    } else if (isYesterday(localDate)) {
      formattedDate = t('yesterday');
    } else if (isThisYear(localDate)) {
      formattedDate = Util.formatUTCtoLocal(dateStr, DATE_PATTERN_DD_MMM);
    } else {
      formattedDate = Util.formatUTCtoLocal(dateStr, DATE_PATTERN_DD_MMM_YYYY);
    }

    return formattedDate;
  }

  /**
   * getChatFormatTime function
   *
   * This function formats a date string for chat display, extracting only the time.
   *
   * @param {string} dateStr (optional) The date string in UTC format.
   * @returns {string} The formatted time string for chat display (or empty string).
   */
  public static getChatFormatTime(dateStr?: string): string {

    let formattedTime = '';
    if (dateStr) {
      formattedTime = Util.formatUTCtoLocal(dateStr, TIME_PATTERN_HH_MM_AAA);
    }

    return formattedTime;
  }

  /**
   * isPrivateChat function
   *
   * This function checks if a conversation is a private chat.
   *
   * @param {ConversationData} conversation (optional) The conversation data object.
   * @returns {boolean} True if the conversation is private, false otherwise.
   */
  public static isPrivateChat(conversation?: ConversationData): boolean {

    return Boolean(conversation?.interactionType === InteractionType.Private);
  }

  /**
   * isGroupChat function
   *
   * This function checks if a conversation is a group chat.
   *
   * @param {ConversationData} conversation (optional) The conversation data object.
   * @returns {boolean} True if the conversation is a group chat, false otherwise.
   */
  public static isGroupChat(conversation?: ConversationData): boolean {

    return Boolean(conversation?.interactionType === InteractionType.Group);
  }

  /**
   * hasChatHistory function
   *
   * This function checks if a conversation has chat history.
   *
   * @param {ConversationData} conversation (optional) The conversation data object.
   * @returns {boolean} True if the conversation has chat history, false otherwise.
   */
  public static hasChatHistory(conversation?: ConversationData): boolean {

    return Boolean(conversation?.chatHistory && !Util.isArrayEmpty(conversation.chatHistory.content));
  }

  /**
   * isLoggedInUser function
   *
   * This function checks if the provided login ID matches the logged-in user's ID.
   *
   * @param {string} loginId The login ID to compare.
   * @returns {boolean} True if the login ID matches the logged-in user, false otherwise.
   */
  public static isLoggedInUser(loginId: string): boolean {

    return loginId === LoginUtil.getLoginId();
  }

  /**
   * getDateHeader function
   *
   * This function formats a date string for use as a chat history date header
   * based on its relative position to the current date.
   *
   * @param {string} dateStr The date string in UTC format.
   * @returns {string} The formatted date string for the chat history header.
   */
  public static getDateHeader(dateStr: string): string {

    let formattedDate = '';
    if (isToday(dateStr)) {
      formattedDate = t('today');
    } else if (isYesterday(dateStr)) {
      formattedDate = t('yesterday');
    } else if (isThisYear(dateStr)) {
      formattedDate = Util.formatUTCtoLocal(dateStr, DATE_PATTERN_DD_MMM);
    } else {
      formattedDate = Util.formatUTCtoLocal(dateStr, DATE_PATTERN_DD_MMM_YYYY);
    }

    return formattedDate;
  }

  /**
   * Checks if the given message priority is 'Urgent'.
   *
   * @param {MessagePriority} priority - The priority of the message.
   * @returns {boolean} - Returns true if the priority is 'Urgent', otherwise false.
   */
  public static isUrgent(priority: MessagePriority): boolean {

    return priority === MessagePriority.Urgent;
  }

  /**
   * Checks if a conversation object matches a given room key.
   *
   * @param conversation {ConversationData} Conversation data object.
   * @param roomKey {string} Room key to match against.
   *
   * @returns {boolean} True if the conversation matches the room key, false otherwise.
   */
  private static isMatchingConversation(conversation: ConversationData, roomKey: string): boolean {

    const loginId = LoginUtil.getLoginId();

    return conversation.groupRoomKey === roomKey ||
      (conversation.senderLoginId === roomKey && conversation.senderLoginId !== loginId) ||
      (conversation.recipientLoginId === roomKey && conversation.recipientLoginId !== loginId) ||
      (conversation.privateRoomKey === roomKey)
  }

  /**
   * Finds a matching conversation in the given list based on the provided room key.
   * Iterates through the conversation list and returns the first conversation that matches the given room key.
   * @param {Array<ConversationData>} conversationList - The list of conversations to search through.
   * @param {string} roomKey - The room key to match against.
   * @returns {ConversationData | undefined} The matching conversation, or undefined if not found.
   */
  private static getMatchingConversation(conversationList: Array<ConversationData>, roomKey: string) {
    let matchingConversation;
    for (const conversation of conversationList) {
      if (ChatUtil.isMatchingConversation(conversation, roomKey)) {
        matchingConversation = conversation;
        break;
      }
    }

    return matchingConversation;
  }

  /**
   * Removes duplicate messages and sorts them by creation time in descending order (newest first).
   *
   * @param chatMessageList {Array<MessageData>} Array of message objects.
   *
   * @returns {Array<MessageData>} Array containing unique messages sorted by creation time (newest first).
   */
  private static removeDuplicateAndSort(chatMessageList: Array<MessageData>): Array<MessageData> {
    const uniqueMessagesMap = new Map<string, MessageData>();
    chatMessageList.forEach(message => {
      // eslint-disable-next-line
      uniqueMessagesMap.set(message.messageId!, message);
    });
    const uniqueChatMessageList = Array.from(uniqueMessagesMap.values());

    // Sort based on createdAt
    // eslint-disable-next-line
    uniqueChatMessageList.sort((b, a) => new Date(a.createdAt!).getTime() - new Date(b.createdAt!).getTime());
    return uniqueChatMessageList
  }

  /**
   * Updates the conversation list with new chat history(group & private) and member data(only for group).
   *
   * @param {Array<ConversationData>} conversationList - The current conversation list.
   * @param {ChatHistoryDispatchPayload} chatHistoryPayload - The payload containing chat history and member data.
   * @returns {Array<ConversationData>} - The updated conversation list.
   */
  public static getUpdatedConversationList(
    conversationList: Array<ConversationData>,
    chatHistoryPayload: ChatHistoryDispatchPayload
  ): Array<ConversationData> {
    const hasChatHistory = !Util.isArrayEmpty(chatHistoryPayload.chatHistory?.content);
    for (const conversation of conversationList) {
      if (ChatUtil.isMatchingConversation(conversation, chatHistoryPayload.roomKey)) {
        if (hasChatHistory) {
          const chatMessageList: Array<MessageData> = Util.isArrayEmpty(conversation.chatHistory?.content) ?
            chatHistoryPayload.chatHistory.content : [...conversation.chatHistory?.content ?? [],
            ...chatHistoryPayload.chatHistory.content];
          conversation.chatHistory = {
            ...chatHistoryPayload.chatHistory,
            content: ChatUtil.removeDuplicateAndSort(chatMessageList)
          };
        }
        conversation.groupMembers = chatHistoryPayload.memberList;
        conversation.isMember = true;
        break; // Exit the loop after updating the matching conversation
      }
    }

    return conversationList;
  }

  /**
   * Updates the conversation list with a new message.
   *
   * @param conversationList {Array<ConversationData>} Array of conversation objects.
   * @param messageData {MessageData} Object containing message data.
   *
   * @returns {Array<ConversationData>} Updated conversation list with added message.
   */
  public static addMessageToConversation(
    conversationList: Array<ConversationData>,
    messageData: MessageData
  ): Array<ConversationData> {

    if (messageData) {
      const loginId = LoginUtil.getLoginId();
      let roomKey: string | undefined = messageData.groupRoomKey; // Group chat
      if (!roomKey) { // private chat 
        roomKey = messageData.senderLoginId === loginId ? messageData.recipientLoginId : messageData.senderLoginId;
      }
      let conversation = ChatUtil.getMatchingConversation(conversationList, roomKey ?? `${loginId}_${roomKey}`);
      if (!conversation) { // A new private chat received
        // This condition should be invoked only for a new private chat.
        conversation = {
          ...messageData,
          privateRoomKey: messageData.privateRoomKey ?? '',
          groupRoomKey: '',
          createdAt: messageData.createdAt ?? '',
          interactionType: InteractionType.Private,
          lastContentText: (messageData.file?.name || messageData.attachmentKey) ?? messageData.contentText,
          lastContentTime: messageData.createdAt ?? '',
          senderLoginId: loginId,
          senderName: LoginUtil.getUserFullName(),
          recipientLoginId: (ChatUtil.isLoggedInUser(messageData.senderLoginId) ? messageData.recipientLoginId
              : messageData.senderLoginId) ?? '',
          recipientName: (ChatUtil.isLoggedInUser(messageData.senderLoginId) ? messageData.recipientName
            : messageData.senderName) ?? '',
          recipientImageKey: ChatUtil.isLoggedInUser(messageData.senderLoginId) ? messageData.recipientImageKey
            : messageData.senderImageKey,
          recipientThumbnailImageKey: ChatUtil.isLoggedInUser(messageData.senderLoginId) ? messageData.recipientThumbnailImageKey
            : messageData.senderThumbnailImageKey,
          name: '',
          description: '',
          recipientActualSessionStatus: ActualSessionStatus.Online,
          recipientAssignedSessionStatus: AssignedSessionStatus.Available,
          count: 0,
          createdByLoginId: messageData.senderLoginId,
          createdByName: messageData.senderName
        };
        conversationList.push(conversation);
        //Mark the conversation as delivered
      }
      conversation.privateRoomKey = conversation.privateRoomKey ?? messageData.privateRoomKey;
      if (!conversation.chatHistory || !conversation.chatHistory.content) {
        conversation.chatHistory = { total: 0, content: [] };
      }
      if (messageData.localMessageId) {
        conversation.chatHistory.content = [...conversation.chatHistory.content
          .filter(message => message.localMessageId !== messageData.localMessageId)
        ];
      }
      conversation.chatHistory.content.unshift(messageData);
      conversation.lastContentText = (messageData.file?.name || messageData.attachmentKey) ?? messageData.contentText;
      conversation.lastContentTime = messageData.createdAt ?? '';
      conversation.chatHistory.total = conversation.chatHistory.content.length;
      conversation.lastSenderLoginId = messageData.senderLoginId;
      conversation.lastSenderName = messageData.senderName;
      if (messageData.senderLoginId !== loginId) { // Received chat
        conversation.count += 1;
        conversation.hasPriority = conversation.hasPriority || ChatUtil.isUrgent(messageData.priority);
      }
    }

    return conversationList;
  }

/**
   * updateMarkAsReadStatus function
   *
   * This function updates the `isRead` status of messages in the provided `conversationList` based on the
   * given `markAsReadData`. The function handles both `ChatInteractionMarkRequest` and `MarkedInteractionData`
   * to update the messages as read.
   *
   * It first checks the type of `markAsReadData` and then processes the messages accordingly.
   *
   * - For `ChatInteractionMarkRequest`, it uses the `roomKey` and the list of `messageIds` to mark the
   *   specified messages as read.
   * - For `MarkedInteractionData`, it uses either `groupRoomKey` or `privateRoomKey`, along with the
   *   `markedNewMessageIds` and an optional `readAt` timestamp.
   *
   * @param {Array<ConversationData>} conversationList - An array of conversation data objects to be updated.
   * Each conversation contains a chat history that may include messages to mark as read.
   *
   * @param {ChatInteractionMarkRequest | MarkedInteractionData} markAsReadData
   *
   * - `ChatInteractionMarkRequest` contains `roomKey` and `messageIds`.
   * - `MarkedInteractionData` contains `groupRoomKey`, `privateRoomKey`,`markedNewMessageIds` and `readAt` timestamp.
   *
   * @returns {Array<ConversationData>} - The updated conversation list with marked messages as read.
   *
   */
public static updateMarkAsReadStatus(
  conversationList: Array<ConversationData>,
  markAsReadData: ChatInteractionMarkRequest | MarkedInteractionData
): Array<ConversationData> {
  console.log('checks', markAsReadData);
  if (ChatUtil.isChatInteractionMarkRequest(markAsReadData)) {
    // Handle ChatInteractionMarkRequest
    console.log('checks in ChatInteractionMarkRequest', markAsReadData);
    for (const conversation of conversationList) {
      if (
        conversation.groupRoomKey === markAsReadData.roomKey ||
        conversation.privateRoomKey === markAsReadData.roomKey
      ) {          
        conversation.chatHistory?.content.forEach(message => {
          if (markAsReadData.messageIds.includes(message.messageId ?? '')) {
            message.isRead = true
          }
        })
        const count = conversation.count - markAsReadData.messageIds.length
        conversation.count = count < 0 ? 0 : count
        if (conversation.count === 0) {
          conversation.hasPriority = false
        }
        break;
      }
    }
  } else {
    // Handle MarkedInteractionData
    if (markAsReadData.interactionType === InteractionType.Group) {
      const { readAt, markedNewMessageIds, groupRoomKey } = markAsReadData;
      for (const conversation of conversationList) {        
        if (conversation.groupRoomKey === groupRoomKey) {
          conversation.chatHistory?.content.forEach(message => {
            console.log('conversation', conversation);
            if (message.messageId && markedNewMessageIds.includes(message.messageId)) {
              message.isRead = true;
              message.readAt = readAt;
            }
          });
        const count = conversation.count - markedNewMessageIds.length
        conversation.count = count < 0 ? 0 : count
        if (conversation.count === 0) {
          conversation.hasPriority = false
        }
        break;
        }
      }
    } else {
      const { readAt, markedNewMessageIds, privateRoomKey } = markAsReadData;
      for (const conversation of conversationList) {        
        if (conversation.privateRoomKey === privateRoomKey) {
          conversation.chatHistory?.content.forEach(message => {
            if (message.messageId && markedNewMessageIds.includes(message.messageId)) {
              message.isRead = true;
              message.readAt = readAt;
            }
          });
        const count = conversation.count - markedNewMessageIds.length
        conversation.count = count < 0 ? 0 : count
        if (conversation.count === 0) {
          conversation.hasPriority = false
        }
        break;        
        }
      }
    }
  }

  return conversationList;
}


  /**
   * Checks if all users in the message info list have read the message.
   *
   * @param {Array<MessageInfo>} messageInfoList - The list of message info objects.
   *
   * @returns {boolean} True if all users have read the message, false otherwise.
   */
  private static isReadByAll(messageInfoList: Array<MessageInfo>): boolean {
    for (const item of messageInfoList) {
      if (!item.readAt) {
        return false;
      }
    }

    return true;
  }

  /**
   * Updates the message info in the conversation list with the provided message data.
   *
   * @param {Array<ConversationData>} conversationList - The list of conversation data objects.
   * @param {MessageData} messageData - The message data object containing the updated message info.
   *
   * @returns {Array<ConversationData>} The updated conversation list.
   */
  public static updateMessageInfo(
    conversationList: Array<ConversationData>,
    messageData: MessageData
  ): Array<ConversationData> {

    for (const conversation of conversationList) {
      if (conversation.groupRoomKey === messageData.groupRoomKey) {
        for (const message of (conversation.chatHistory?.content ?? [])) {
          if (message.messageId === messageData.messageId) {
            message.messageInfo = messageData.messageInfo;
            const isReadAll = ChatUtil.isReadByAll(messageData.messageInfo ?? []);
            message.isReadAll = isReadAll;
            message.isFetched = isReadAll;
            break;
          }
        }
        break; // Exit the loop after updating the matching conversation
      }
    }

    return conversationList;
  }

  /**
   * Updates the message attachment information in the conversation list.
   *
   * @param {Array<ConversationData>} conversationList - The list of conversation data objects.
   * @param {MessageData} messageData - The message data with updated attachment information.
   *
   * @returns {Array<ConversationData>} The updated conversation list.
   */
  public static updateMessageAttachment(
    conversationList: Array<ConversationData>,
    messageData: MessageData
  ): Array<ConversationData> {
    const roomKey = messageData.groupRoomKey ?? messageData.privateRoomKey;
    const conversation = ChatUtil.getMatchingConversation(conversationList, roomKey ?? '');
    if (conversation) {
      for (const message of (conversation.chatHistory?.content ?? [])) {
        if (message.messageId === messageData.messageId) {
          Object.assign(message, { ...messageData });
          break;
        }
      }
    }

    return conversationList;
  }

  public static hasKey<K extends string>(key: K, obj: object): obj is Record<K, unknown> {
    
    return key in obj;
  }

  public static getRoomKey(message: MessageData) {

    return ChatUtil.hasKey(ChatRoomKey.Private, message)
      ? String(message[ ChatRoomKey.Private ])
      : ChatUtil.hasKey(ChatRoomKey.Group, message)
        ? String(message[ ChatRoomKey.Group ])
        : undefined;
  }

  /**
   * updateMarkAsDeliveredStatus function
   *
   * This function updates the delivery status of messages in the `conversationList`. It accepts
   * either a `ChatInteractionMarkRequest` or a `MarkedInteractionData` object to determine which
   * messages to mark as delivered. It then updates the `deliveredAt` timestamp for each message
   * in the conversation history based on the provided data.
   *
   * The function first checks the type of the `markAsDeliveredData` parameter. If it is a
   * `ChatInteractionMarkRequest`, it uses the `roomKey` and `messageIds` to identify and mark
   * the messages as delivered. If it is a `MarkedInteractionData`, it uses the `groupRoomKey`
   * or `privateRoomKey` and `markedNewMessageIds`, optionally using the `deliveredAt` timestamp
   * provided in the data, or defaults to the current date and time if not provided.
   *
   * @param {Array<ConversationData>} conversationList - An array of conversation data objects that contain
   * the chat history to be updated.
   *
   * @param {ChatInteractionMarkRequest | MarkedInteractionData} markAsDeliveredData - This parameter can be
   * either a `ChatInteractionMarkRequest` or a `MarkedInteractionData`:
   * - `ChatInteractionMarkRequest`: Contains the `roomKey` and `messageIds` to mark messages as delivered.
   * - `MarkedInteractionData`: Contains the `groupRoomKey` or `privateRoomKey`, `markedNewMessageIds`, and an optional
   * `deliveredAt` timestamp.
   *
   * @returns {Array<ConversationData>} - The updated conversation list with messages marked as delivered.
   * Each conversation's messages will have their `deliveredAt` timestamp updated to reflect the delivery time.
   */
  public static updateMarkAsDeliveredStatus(
    conversationList: Array<ConversationData>,
    markAsDeliveredData: ChatInteractionMarkRequest | MarkedInteractionData
  ): Array<ConversationData> {
    if (ChatUtil.isChatInteractionMarkRequest(markAsDeliveredData)) {
      // If it's a ChatInteractionMarkRequest, use roomKey and messageIds
      for (const conversation of conversationList) {
        if (
          conversation.groupRoomKey === markAsDeliveredData.roomKey ||
          conversation.privateRoomKey === markAsDeliveredData.roomKey
        ) {
          conversation.chatHistory?.content.forEach(message => {
            if (markAsDeliveredData.messageIds.includes(message.messageId ?? '')) {
              message.deliveredAt = new Date().toISOString();
            }
          })
          break;
        }
      }
    } else {
      // process MarkedInteractionData
      if (markAsDeliveredData.interactionType === InteractionType.Group) {
        for (const conversation of conversationList) {
          const { deliveredAt, markedNewMessageIds, groupRoomKey } = markAsDeliveredData
          if (conversation.groupRoomKey === groupRoomKey) {
            markedNewMessageIds.forEach(messageId => {
              const message = conversation.chatHistory?.content.find(msg => msg.messageId?.trim() === messageId);
              if (message) {
                message.deliveredAt = deliveredAt;
              }
            })
            break;
          }
        }
      } else {
        for (const conversation of conversationList) {
          const { deliveredAt, markedNewMessageIds, privateRoomKey } = markAsDeliveredData
          if (conversation.privateRoomKey === privateRoomKey) {
            conversation.chatHistory?.content.forEach(message => {
              if (message.messageId && markedNewMessageIds.includes(message.messageId)) {
                message.deliveredAt = deliveredAt;
              }
            })
            break;
          }
        }
      }
    }
    return conversationList
  }

  /**
   * Determines if the provided data is of type `ChatInteractionMarkRequest`.
   * It checks for the presence of the `roomKey` property, which is unique to `ChatInteractionMarkRequest`.
   *
   * @param {ChatInteractionMarkRequest | MarkedInteractionData} data - The object to check.
   * @returns {data is ChatInteractionMarkRequest} - `true` if the object is a `ChatInteractionMarkRequest`, otherwise `false`.
   */
  public static isChatInteractionMarkRequest = (
    data: ChatInteractionMarkRequest | MarkedInteractionData
  ): data is ChatInteractionMarkRequest => {
    return (data as ChatInteractionMarkRequest).roomKey !== undefined
  }
}